import React, { useState, useMemo, useCallback } from 'react';
import { Form, Button, message } from 'antd';
import {
  SimpleSelect,
  FormItem,
  HiddenField,
} from '@xbcb/form-item-components';
import { safeGetMessage, useModal } from '@xbcb/ui-utils';
import { CssSize } from '@xbcb/ui-types';
import cbpDocumentCodes from 'libs/cbpDocumentCodes';
import { StyledModal, StyledTable } from './styles';
import { useMutation } from '@apollo/client';
import type {
  SubmitUsConsumptionEntryDisInput,
  Document,
} from '@xbcb/api-gateway-client';
import { v4 as uuidV4 } from 'uuid';
import { ModalKey } from 'types';
import {
  DocumentTag,
  DocumentStatus,
  DisDocuments,
} from '@xbcb/document-types';
import { createWorkOrderMilestoneMutation } from 'libs/sharedQueries';
import { WorkOrderMilestoneName } from '@xbcb/work-order-types';
import { SubmitUsType86EntryDisInput } from '__generated__/graphql';
import { useBundle } from '@amzn/react-arb-tools';
import {
  submitEntryDis,
  submitType86EntryDis,
  createWorkOrderConfirmation,
  getWorkOrderQuery,
  sendWorkOrderDocumentsTo3p,
} from './sendDocumentModal.query';

interface SendDocumentModalProps {
  recordId?: string;
  documents?: Document[];
  operatorId?: number;
  workOrderVersion?: number;
}

const getDefaultDisCode = (documentTags?: DocumentTag[]) => {
  if (documentTags?.length === 1) {
    const [firstDocumentTag] = documentTags;
    const disDocument =
      DisDocuments.getDisDocumentByDocumentTag(firstDocumentTag);
    return disDocument?.labelCode;
  }
  return undefined;
};

const SendDocumentModal = ({
  recordId,
  documents,
  operatorId,
  workOrderVersion,
}: SendDocumentModalProps) => {
  const [documentsPageBundle] = useBundle('components.DocumentsPage');
  const [form] = Form.useForm();
  const initialSelected: any[] = [];
  const [selectedRowKeys, setSelectedRowKeys] = useState(initialSelected);
  const [isLoading, setIsLoading] = useState(false);
  const isUsConsumptionEntry = recordId?.startsWith('usConsumptionEntry');
  const isUsType86Entry = recordId?.startsWith('usType86Entry');
  const isCustomsDeclaration = recordId?.startsWith('customsDeclaration');
  const shouldSendToCbp = isUsConsumptionEntry || isUsType86Entry;

  const {
    visible: isModalVisible,
    modalProps,
    closeModal,
  } = useModal(ModalKey.SEND_DOCUMENT);
  // get document if a specific document is selected
  const document: Document | undefined = modalProps?.document;

  const documentSelectionCandidates = useMemo(
    () => (document ? [document] : documents),
    [document, documents],
  );

  const handleClose = () => {
    closeModal();
  };

  const buildColumns = () => {
    const columnsArray: [Object] = [
      {
        title: safeGetMessage(documentsPageBundle, 'file_name'),
        key: 'filename',
        dataIndex: 'filename',
        width: 'auto',
        render: (text: string, record: any) => {
          return (
            <div>
              {text}
              <HiddenField
                localNamePath={[record.index, 'docId']}
                form={form}
                value={record.docId}
              />
            </div>
          );
        },
      },
    ];

    const disCodeColumn = {
      title: safeGetMessage(documentsPageBundle, 'dis_code'),
      key: 'disCode',
      dataIndex: 'disCode',
      render: (text: string, record: any) => {
        const defaultDisCode = getDefaultDisCode(record.documentTags);
        const isRequired = document
          ? true
          : selectedRowKeys.includes(record.docId)
          ? true
          : false;
        return (
          <SimpleSelect
            showSearch
            form={form}
            key={record.docId}
            $itemSize={CssSize.SHORT}
            required={isRequired}
            dropdownMatchSelectWidth={false}
            fullNamePath={[record.index, 'cbpLabel']}
            localNamePath={[record.index, 'cbpLabel']}
            map={cbpDocumentCodes}
            initialValue={defaultDisCode}
            allowClear
            aria-label={`cbpLabel${record.index}`}
          />
        );
      },
    };

    if (shouldSendToCbp) {
      columnsArray.push(disCodeColumn);
    }

    return columnsArray;
  };

  const buildData = () => {
    return documentSelectionCandidates?.map((doc, index) => {
      const { id, fileName, extension, documentTags } = doc;
      return {
        filename: fileName + '.' + extension,
        documentTags: documentTags,
        key: id,
        docId: id,
        index,
      };
    });
  };

  const submitDisabled = document ? false : selectedRowKeys.length < 1;

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (keys: any[], rows: any[]) => {
      setSelectedRowKeys(keys);
    },
  };

  const [sendDisDocument] = useMutation(
    isUsType86Entry ? submitType86EntryDis : submitEntryDis,
  );

  const sendDisDocs = useCallback(
    async (
      input: SubmitUsConsumptionEntryDisInput | SubmitUsType86EntryDisInput,
    ) => {
      const { data, errors } = await sendDisDocument({
        variables: {
          idempotencyKey: uuidV4(),
          input,
          recordId,
        },
      });
      if (errors) {
        void message.error(
          safeGetMessage(documentsPageBundle, 'submission_failed', { errors }),
        );
      } else {
        void message.success(
          safeGetMessage(documentsPageBundle, 'submitted_to_cbp'),
        );
      }
    },
    [documentsPageBundle, recordId, sendDisDocument],
  );

  const handleSubmitToCBP = useCallback(
    async (e: any) => {
      e.preventDefault();
      try {
        await form.validateFields();
      } catch (e) {
        void message.error(
          safeGetMessage(documentsPageBundle, 'ensure_tag', { toCbp: true }),
        );
        return;
      }
      setIsLoading(true);
      const formObj = form.getFieldsValue();
      const input = {
        documents: Object.entries(formObj).reduce((arr: any, item: any) => {
          const { docId, cbpLabel } = item[1];
          if (document?.id || selectedRowKeys.includes(docId)) {
            arr.push({
              document: { id: docId },
              cbpLabel,
            });
          }
          return arr;
        }, []),
      };

      await sendDisDocs(input);
      setIsLoading(false);
      closeModal();
    },
    [
      closeModal,
      document?.id,
      documentsPageBundle,
      form,
      selectedRowKeys,
      sendDisDocs,
    ],
  );

  const [sendDocumentToSeller] = useMutation(createWorkOrderConfirmation);

  const sendDocsToSeller = useCallback(async () => {
    const { data, errors } = await sendDocumentToSeller({
      variables: {
        idempotencyKey: uuidV4(),
        input: {
          workOrder: {
            id: recordId,
            version: workOrderVersion,
          },
          operator: {
            id: operatorId,
          },
        },
      },
    });

    if (errors) {
      void message.error(
        safeGetMessage(documentsPageBundle, 'submission_failed', { errors }),
      );
    } else {
      void message.success(
        safeGetMessage(documentsPageBundle, 'sent_to_seller'),
      );
    }
  }, [
    documentsPageBundle,
    operatorId,
    recordId,
    sendDocumentToSeller,
    workOrderVersion,
  ]);

  const [createWorkOrderMilestone] = useMutation(
    createWorkOrderMilestoneMutation,
    {
      refetchQueries: () => [
        {
          query: getWorkOrderQuery,
          variables: { id: recordId, version: workOrderVersion },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const handleSubmitToSeller = useCallback(
    async (e: any) => {
      e.preventDefault();
      try {
        await form.validateFields();
      } catch (e) {
        void message.error(
          safeGetMessage(documentsPageBundle, 'ensure_tag', { toCbp: false }),
        );
        return;
      }
      if (document?.status === DocumentStatus.PENDING_UPLOAD) {
        void message.error(
          safeGetMessage(documentsPageBundle, 'document_upload_error'),
        );
        return;
      }
      setIsLoading(true);

      if (document?.documentTags.includes(DocumentTag.CUSTOMS_ENTRY_DRAFT)) {
        await sendDocsToSeller();
      } else if (document?.documentTags.includes(DocumentTag.CUSTOMS_ENTRY)) {
        await createWorkOrderMilestone({
          variables: {
            idempotencyKey: `${recordId}:${workOrderVersion}:${WorkOrderMilestoneName.FINAL_CUSTOMS_ENTRY_DOCUMENT_UPLOADED}`,
            input: {
              allowDuplicate: true,
              name: WorkOrderMilestoneName.FINAL_CUSTOMS_ENTRY_DOCUMENT_UPLOADED,
              workOrder: {
                id: recordId,
                version: workOrderVersion,
              },
            },
          },
        });
      }

      setIsLoading(false);
      closeModal();
    },
    [
      closeModal,
      createWorkOrderMilestone,
      document?.documentTags,
      document?.status,
      documentsPageBundle,
      form,
      recordId,
      sendDocsToSeller,
      workOrderVersion,
    ],
  );

  const [sendDocumentToBroker] = useMutation(sendWorkOrderDocumentsTo3p);

  const sendDocsToBroker = useCallback(async () => {
    if (
      documentSelectionCandidates?.find(
        (doc: Document) => !doc.documentTags?.length,
      )
    ) {
      void message.error(
        safeGetMessage(documentsPageBundle, 'ensure_tag', { toCbp: false }),
      );
      return;
    }
    const documents = documentSelectionCandidates
      ?.filter(
        ({ id }: Document) =>
          id && (selectedRowKeys.includes(id) || id === document?.id),
      )
      .map(({ id }) => ({
        id,
      }));

    if (!documents?.length) {
      // if this happens, it means there are no ids on the selected documents. Likely impossible, but compiler doesn't know
      void message.error(safeGetMessage(documentsPageBundle, 'unknown_issue'));
      return;
    }
    const { data, errors } = await sendDocumentToBroker({
      variables: {
        idempotencyKey: uuidV4(),
        input: {
          documents,
        },
        workOrderId: recordId,
      },
    });

    if (errors) {
      void message.error(
        safeGetMessage(documentsPageBundle, 'submission_failed', { errors }),
      );
    } else {
      void message.success(
        safeGetMessage(documentsPageBundle, 'sent_to_broker'),
      );
    }
  }, [
    document?.id,
    documentSelectionCandidates,
    documentsPageBundle,
    recordId,
    selectedRowKeys,
    sendDocumentToBroker,
  ]);

  const handleSubmitToBroker = useCallback(
    async (e: any) => {
      e.preventDefault();
      try {
        await form.validateFields();
      } catch (e) {
        void message.error(
          safeGetMessage(documentsPageBundle, 'ensure_tag', { toCbp: false }),
        );
        return;
      }
      if (document?.status === DocumentStatus.PENDING_UPLOAD) {
        void message.error(
          safeGetMessage(documentsPageBundle, 'document_upload_error'),
        );
        return;
      }
      setIsLoading(true);
      await sendDocsToBroker();
      setIsLoading(false);
      closeModal();
    },
    [closeModal, document?.status, documentsPageBundle, form, sendDocsToBroker],
  );

  const sendButtonAction = useMemo(
    () =>
      shouldSendToCbp
        ? handleSubmitToCBP
        : isCustomsDeclaration
        ? handleSubmitToBroker
        : handleSubmitToSeller,
    [
      shouldSendToCbp,
      handleSubmitToCBP,
      isCustomsDeclaration,
      handleSubmitToBroker,
      handleSubmitToSeller,
    ],
  );

  const sendButtonText = useMemo(
    () =>
      safeGetMessage(
        documentsPageBundle,
        shouldSendToCbp
          ? 'send_to_cbp'
          : isCustomsDeclaration
          ? 'send_to_broker'
          : 'send_to_seller',
      ),
    [documentsPageBundle, isCustomsDeclaration, shouldSendToCbp],
  );

  return (
    <StyledModal
      destroyOnClose={true}
      maskClosable={true}
      open={isModalVisible}
      width={860}
      closable={false}
      footer={[
        <Button
          onClick={sendButtonAction}
          type="primary"
          size="large"
          loading={isLoading}
          htmlType="submit"
          disabled={submitDisabled}
          key="sendButton"
          data-testid="sendButton"
        >
          {sendButtonText}
        </Button>,
      ]}
      onCancel={handleClose}
    >
      <Form form={form}>
        <FormItem>
          <StyledTable
            columns={buildColumns()}
            dataSource={buildData()}
            rowKey={(record: any) => record.docId}
            rowSelection={document ? undefined : rowSelection}
            pagination={false}
          />
        </FormItem>
      </Form>
    </StyledModal>
  );
};

export default SendDocumentModal;
