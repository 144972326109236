import React from 'react';
import type { Tag } from '@xbcb/api-gateway-client';
import { PartyFormBase, PartyFormBaseProps } from '@xbcb/form-components';
import Docs from 'components/Docs';
import ShipperSelect from 'components/ShipperSelect';
import { AccountType, RecordType } from '@xbcb/shared-types';
import { useLocation } from 'react-router-dom';
import { useCurrentUser, usePermissions } from 'libs/hooks';
import { locationToObjectType } from '@xbcb/ui-utils';
import { DocumentPermissions } from '@xbcb/ui-types';
// import { recordTypeToRecordFieldsMap } from '@xbcb/apollo-client';

// No need to take `Docs` as a prop, as the purpose of this wrapper component
// is to add the Docs component
type PartyFormProps = Omit<
  PartyFormBaseProps,
  'Docs' | 'showBillingDetails'
> & {
  searchQueryTags?: Tag[];
  customPermissions?: DocumentPermissions;
  children?: React.ReactNode;
};

const PartyForm: React.FC<PartyFormProps> = (props) => {
  const { pathname } = useLocation();
  const { accountType } = useCurrentUser();

  const { form, disabled, searchQueryTags, customPermissions } = props;
  // If we are on the create page for a record (i.e. `pathname?.endsWith('/create')`, we
  // should the `ShipperSelect`.
  const isCreate = pathname?.endsWith('/create');
  const recordType = locationToObjectType(pathname);
  const recordPermissions = usePermissions(recordType as RecordType);
  const shipperSelect =
    isCreate && recordType ? (
      <ShipperSelect
        disabled={disabled}
        form={form}
        recordType={recordType as RecordType}
        multiple={true}
      />
    ) : undefined;
  return (
    <PartyFormBase
      {...props}
      accountType={accountType}
      ShipperSelect={shipperSelect}
      Docs={
        <Docs
          fullWidth
          searchQueryTags={searchQueryTags}
          customPermissions={
            customPermissions || recordPermissions?.permissions
          }
        />
      }
      showBillingDetails={accountType === AccountType.OPERATOR}
    />
  );
};

export default PartyForm;
