import { Store } from 'redux';
import {
  UsConsumptionEntryLine,
  UsType86EntryLine,
  UsPostSummaryCorrectionLine,
} from '@xbcb/api-gateway-client';
import { formatHTS } from '@xbcb/hts-client-utils';
import {
  ValidationErrorType,
  AppRecordValidateResult,
  NamePath,
} from '@xbcb/ui-types';
import { section232AluminumRequirementsFormFieldsNamePaths } from 'components/AluminumSmeltAndCastCountryDetail/component';
import { NRBS_RECORD_NUMBER_LEN } from '../constant';
import {
  DEFAULT_EFFECTIVE_DATE,
  formatMomentAsEffectiveDateString,
} from 'libs/dateUtils';
import {
  isSection232AluminumSmeltAndCastApplicableForAnyTariff,
  isSection232SteelMeltAndPourApplicableForAnyTariff,
} from 'libs/htsHelpers';
import moment from 'moment';
import {
  Section232SteelRequirementsFields,
  steelRequirementsFieldPrefix,
} from 'components/SteelMeltAndPourCountryDetail/component';

export type EntryLine =
  | UsConsumptionEntryLine
  | UsType86EntryLine
  | UsPostSummaryCorrectionLine;

export const validateNRBSNumber = (
  line: EntryLine,
  lineIndex: number,
): AppRecordValidateResult => {
  if (
    'nrbsRecordNumber' in line &&
    line?.nrbsRecordNumber &&
    line.nrbsRecordNumber.length !== NRBS_RECORD_NUMBER_LEN
  ) {
    return {
      additionalErrors: [
        {
          title: 'Nrbs Recording Number',
          type: ValidationErrorType.GENERAL,
          messages: [
            `Nrbs Record Number must be 10 alphanumeric characters in line ${
              lineIndex + 1
            }`,
          ],
        },
      ],
      validateFields: [],
    };
  } else return {};
};

export const validateSection232Aluminum = (
  line: EntryLine,
  lineNamePath: NamePath,
  arrivalDate?: moment.Moment,
  store?: Store,
): AppRecordValidateResult => {
  const result: AppRecordValidateResult = { validateFields: [] };
  const storeState = store?.getState();
  const estimatedImportDateString = arrivalDate
    ? formatMomentAsEffectiveDateString(arrivalDate)
    : DEFAULT_EFFECTIVE_DATE;
  const effectiveDateHtsHierarchy =
    storeState?.hts?.usHtsData?.[estimatedImportDateString];

  const htsObjects =
    line.tariffs?.map(
      (tariff) =>
        effectiveDateHtsHierarchy?.[formatHTS(tariff.htsNumber)] ?? {},
    ) || [];

  if (
    isSection232AluminumSmeltAndCastApplicableForAnyTariff(
      htsObjects,
      arrivalDate?.toDate(),
    )
  ) {
    section232AluminumRequirementsFormFieldsNamePaths.forEach(
      (fieldNamePath) => {
        result.validateFields?.push([...lineNamePath, ...fieldNamePath]);
      },
    );
  }
  return result;
};

export const validateSection232Steel = (
  line: EntryLine,
  lineNamePath: NamePath,
  arrivalDate?: moment.Moment,
  store?: Store,
): AppRecordValidateResult => {
  const result: AppRecordValidateResult = { validateFields: [] };
  const storeState = store?.getState();
  const estimatedImportDateString = arrivalDate
    ? formatMomentAsEffectiveDateString(arrivalDate)
    : DEFAULT_EFFECTIVE_DATE;
  const effectiveDateHtsHierarchy =
    storeState?.hts?.usHtsData?.[estimatedImportDateString];

  const htsObjects =
    line.tariffs?.map(
      (tariff) =>
        effectiveDateHtsHierarchy?.[formatHTS(tariff.htsNumber)] ?? {},
    ) || [];

  if (
    isSection232SteelMeltAndPourApplicableForAnyTariff(
      htsObjects,
      arrivalDate?.toDate(),
    )
  ) {
    if (!line.steelRequirements?.derivativeSteelProductOfMexicoOnly) {
      result.validateFields?.push([
        ...lineNamePath,
        steelRequirementsFieldPrefix,
        Section232SteelRequirementsFields.STEEL_COUNTRY_OF_MELT_AND_POUR,
      ]);
    }
  }
  return result;
};
