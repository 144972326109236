import { AccountType, RecordType } from '@xbcb/shared-types';
import { MessageBundle } from '@xbcb/ui-types';
import { getLocalizedRecordName } from 'libs/localizationHelpers';

export interface MemoConfiguration {
  // Account types that cannot view the memos
  blocklist?: AccountType[];
  // If label is not provided and labelNamepath is undefined then text is used as
  // the title for the AppRecordMemoModal.
  text: string;
  key: string;
  // Indicates which field in the record to use when creating the title in
  // AppRecordMemoModal (title = "Record[labelNamepath] Memos").
  // If not provided, then the modal will use either "label" or the text property
  // instead. This is a . separated path to the label used in lodash.get.
  labelNamePath?: string;
  label?: string;
  // Indicates which field in the record to use when locating the memos.
  // If not provided, the top level of the AppRecord will be used to find memos.
  // This is a . separated path to the record used in lodash.get.
  namePathToRecordWithMemos?: string;
}

const getShipmentText = (bundle: MessageBundle) =>
  getLocalizedRecordName(bundle, RecordType.SHIPMENT, AccountType.OPERATOR);

const getShipperText = (bundle: MessageBundle) =>
  getLocalizedRecordName(bundle, RecordType.SHIPPER, AccountType.OPERATOR);

const getUsIorText = (bundle: MessageBundle) =>
  getLocalizedRecordName(bundle, RecordType.US_IOR, AccountType.OPERATOR);

const getDeIorText = (bundle: MessageBundle) =>
  getLocalizedRecordName(bundle, RecordType.DE_IOR, AccountType.OPERATOR);

const getGbIorText = (bundle: MessageBundle) =>
  getLocalizedRecordName(bundle, RecordType.GB_IOR, AccountType.OPERATOR);

const getNlIorText = (bundle: MessageBundle) =>
  getLocalizedRecordName(bundle, RecordType.NL_IOR, AccountType.OPERATOR);

const getFrIorText = (bundle: MessageBundle) =>
  getLocalizedRecordName(bundle, RecordType.FR_IOR, AccountType.OPERATOR);

const getImporterText = (bundle: MessageBundle) =>
  getLocalizedRecordName(bundle, RecordType.IMPORTER, AccountType.OPERATOR);

export const getAppRecordMemoConfiguration = (
  bundle: MessageBundle,
): {
  [key: string]: MemoConfiguration[];
} => {
  return {
    [RecordType.SHIPMENT]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'workOrderGroup',
      },
    ],
    [RecordType.SHIPPER]: [
      {
        text: getShipperText(bundle),
        key: 'shipperMemos',
        labelNamePath: 'name',
      },
    ],
    [RecordType.US_CONSUMPTION_ENTRY]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getShipperText(bundle),
        key: 'shipperMemos',
        labelNamePath: 'group.shipper.name',
        namePathToRecordWithMemos: 'group.shipper',
      },
      {
        text: getUsIorText(bundle),
        key: 'usIorMemos',
        labelNamePath: 'ior.usIor.name',
        namePathToRecordWithMemos: 'ior.usIor',
      },
    ],
    [RecordType.US_TYPE86_ENTRY]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getShipperText(bundle),
        key: 'shipperMemos',
        labelNamePath: 'group.shipper.name',
        namePathToRecordWithMemos: 'group.shipper',
      },
      {
        text: getUsIorText(bundle),
        key: 'usIorMemos',
        labelNamePath: 'ior.usIor.name',
        namePathToRecordWithMemos: 'ior.usIor',
      },
    ],
    [RecordType.DE_CUSTOMS_ENTRY]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getShipperText(bundle),
        key: 'shipperMemos',
        labelNamePath: 'group.shipper.name',
        namePathToRecordWithMemos: 'group.shipper',
      },
      {
        text: getDeIorText(bundle),
        key: 'IorMemos',
        labelNamePath: 'ior.ior.name',
        namePathToRecordWithMemos: 'ior.Ior',
      },
    ],
    [RecordType.NL_CUSTOMS_ENTRY]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getShipperText(bundle),
        key: 'shipperMemos',
        labelNamePath: 'group.shipper.name',
        namePathToRecordWithMemos: 'group.shipper',
      },
      {
        text: getNlIorText(bundle),
        key: 'IorMemos',
        labelNamePath: 'ior.ior.name',
        namePathToRecordWithMemos: 'ior.Ior',
      },
    ],
    [RecordType.FR_CUSTOMS_ENTRY]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getShipperText(bundle),
        key: 'shipperMemos',
        labelNamePath: 'group.shipper.name',
        namePathToRecordWithMemos: 'group.shipper',
      },
      {
        text: getFrIorText(bundle),
        key: 'IorMemos',
        labelNamePath: 'ior.ior.name',
        namePathToRecordWithMemos: 'ior.Ior',
      },
    ],
    [RecordType.GB_CUSTOMS_ENTRY]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getShipperText(bundle),
        key: 'shipperMemos',
        labelNamePath: 'group.shipper.name',
        namePathToRecordWithMemos: 'group.shipper',
      },
      {
        text: getGbIorText(bundle),
        key: 'IorMemos',
        labelNamePath: 'ior.ior.name',
        namePathToRecordWithMemos: 'ior.Ior',
      },
    ],
    [RecordType.CUSTOMS_DECLARATION]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getShipperText(bundle),
        key: 'shipperMemos',
        labelNamePath: 'group.shipper.name',
        namePathToRecordWithMemos: 'group.shipper',
      },
      // {
      //   text: importerText,
      //   key: 'ImporterMemos',
      //   labelNamePath: 'goodsShipment.importer.name',
      //   namePathToRecordWithMemos: 'goodsShipment.importer',  // currently, this imported doesn't have memos because it's an interface
      // },
    ],
    [RecordType.US_IOR_CONTINUOUS_BOND_REQUEST]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getUsIorText(bundle),
        key: 'usIorMemos',
        labelNamePath: 'usIor.name',
        namePathToRecordWithMemos: 'usIor',
      },
    ],
    [RecordType.US_IOR]: [
      {
        text: getUsIorText(bundle),
        key: 'usIorMemos',
        labelNamePath: 'name',
      },
    ],
    [RecordType.DE_IOR]: [
      {
        text: getDeIorText(bundle),
        key: 'deIorMemos',
        labelNamePath: 'name',
      },
    ],
    [RecordType.GB_IOR]: [
      {
        text: getGbIorText(bundle),
        key: 'gbIorMemos',
        labelNamePath: 'name',
      },
    ],
    [RecordType.NL_IOR]: [
      {
        text: getNlIorText(bundle),
        key: 'nlIorMemos',
        labelNamePath: 'name',
      },
    ],
    [RecordType.FR_IOR]: [
      {
        text: getFrIorText(bundle),
        key: 'frIorMemos',
        labelNamePath: 'name',
      },
    ],
    [RecordType.US_ISF]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getUsIorText(bundle),
        key: 'usIorMemos',
        labelNamePath: 'ior.usIor.name',
        namePathToRecordWithMemos: 'ior.usIor',
      },
    ],
    [RecordType.US_IN_BOND]: [
      {
        text: getShipmentText(bundle),
        key: 'groupMemos',
        namePathToRecordWithMemos: 'group',
      },
      {
        text: getUsIorText(bundle),
        key: 'usIorMemos',
        labelNamePath: 'ior.usIor.name',
        namePathToRecordWithMemos: 'ior.usIor',
      },
    ],
  };
};
