import React from 'react';
import {
  PopoverContentDiv,
  StyledButton,
  StyledDiv,
  LanguageButton,
  StyledPopover,
} from './style';
import { CaretDownOutlined } from '@ant-design/icons';
import { useLocalizationContext, useBundle } from '@amzn/react-arb-tools';
import { LanguageCode, LanguageEntry, languages } from './languageEntry';
import { safeGetMessage } from '@xbcb/ui-utils';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { DataCyPrefix } from '@xbcb/ui-types';
import { useDispatch } from 'react-redux';
import { setLocalizationContext as setLocale } from 'actions';
import { useTypedSelector } from 'reducers';

function LanguagePicker(props: LanguageEntry) {
  const { code, name, region } = props;
  const { setLocalizationContext } = useLocalizationContext();
  const dispatch = useDispatch();

  const localizationContextBuilder = new LocalizationContextBuilder()
    .withLocale(code)
    .build();

  const toggleLocale = () => {
    setLocalizationContext(localizationContextBuilder);
    // Persist language selection
    dispatch(
      setLocale({
        locale: code,
      }),
    );
  };

  return (
    <LanguageButton key={code} onClick={toggleLocale}>
      <h3>{name}</h3>
      {region} {`(${code})`}
    </LanguageButton>
  );
}

const LanguageSwitcher: React.FC = () => {
  const [appHeaderBundle] = useBundle('components.AppHeader');
  const { localizationContext } = useLocalizationContext();
  const { locale } =
    useTypedSelector((store) => store.localizationContext) ?? {};

  const currentRegion = (locale ?? localizationContext.getLocale())
    .toUpperCase()
    .split('-')[1];

  const LanguageSwitcherContent = () => (
    <PopoverContentDiv>
      {(Object.keys(languages) as LanguageCode[]).map((code) => (
        <>
          <LanguagePicker
            key={languages[code].code}
            code={languages[code].code}
            name={languages[code].name}
            region={languages[code].region}
          />
        </>
      ))}
    </PopoverContentDiv>
  );

  return (
    <StyledDiv data-cy={DataCyPrefix.LANGUAGE_SWITCHER_POPOVER}>
      <StyledPopover
        title={
          <h3>{safeGetMessage(appHeaderBundle, 'select_country_language')}</h3>
        }
        placement="bottomRight"
        content={<LanguageSwitcherContent />}
        trigger={'click'}
      >
        <StyledButton>
          {currentRegion}
          <CaretDownOutlined />
        </StyledButton>
      </StyledPopover>
    </StyledDiv>
  );
};

export default LanguageSwitcher;
