const endpointMap = {
  de: {
    'components.AccountingPointOfContacts': 'translation-de.json',
    'components.ActivationInfo': 'translation-de.json',
    'components.AppHeader': 'translation-de.json',
    'components.AppRecord': 'translation-de.json',
    'components.AppSiderMenu': 'translation-de.json',
    'components.AppTable': 'translation-de.json',
    'components.AssignWorkOrderTaskModal': 'translation-de.json',
    'components.AssignmentTeam': 'translation-de.json',
    'components.AssignmentWorkspace': 'translation-de.json',
    'components.CancelShipmentModal': 'translation-de.json',
    'components.CommodityGroup': 'translation-de.json',
    'components.CreateWorkOrderModal': 'translation-de.json',
    'components.CustomizeTableDrawer': 'translation-de.json',
    'components.DocumentsPage': 'translation-de.json',
    'components.Emails': 'translation-de.json',
    'components.EuIorActivation': 'translation-de.json',
    'components.ImporterEntityActivationSummary': 'translation-de.json',
    'components.InltCharges': 'translation-de.json',
    'components.Invoice': 'translation-de.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-de.json',
    'components.Milestones': 'translation-de.json',
    'components.MilestonesTab': 'translation-de.json',
    'components.NewBundleAvailable': 'translation-de.json',
    'components.Party': 'translation-de.json',
    'components.PasswordHelper': 'translation-de.json',
    'components.PayableInvoiceSummaryForm': 'translation-de.json',
    'components.PersonalInfo': 'translation-de.json',
    'components.ProfileForm': 'translation-de.json',
    'components.RecordSelect': 'translation-de.json',
    'components.RecordTable': 'translation-de.json',
    'components.RejectIorActivationModal': 'translation-de.json',
    'components.Shipment': 'translation-de.json',
    'components.ShipmentInfo': 'translation-de.json',
    'components.ShipperSelect': 'translation-de.json',
    'components.UnplannedCharges': 'translation-de.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-de.json',
    'components.User': 'translation-de.json',
    'components.WorkOrder': 'translation-de.json',
    'components.WorkOrderTabs': 'translation-de.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-de.json',
    'components.WorkOrderTaskInfoCard': 'translation-de.json',
    'components.WorkOrderTaskSteps': 'translation-de.json',
    'components.WorkOrderTasks': 'translation-de.json',
    'components.buttonComponents.MinusButton': 'translation-de.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-de.json',
    'components.formComponents.EuIorInfoCard': 'translation-de.json',
    'components.formComponents.LocationForm': 'translation-de.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-de.json',
    'components.formComponents.PartyFormBase': 'translation-de.json',
    'components.formComponents.PointOfContact': 'translation-de.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-de.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-de.json',
    'components.formComponents.UsCbpInfoCard': 'translation-de.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-de.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-de.json',
    'components.formItemComponents.DUNSNumber': 'translation-de.json',
    'components.formItemComponents.Email': 'translation-de.json',
    'components.formItemComponents.IORNumber': 'translation-de.json',
    'components.formItemComponents.PhoneNumber': 'translation-de.json',
    'components.formItemComponents.SimpleSelect': 'translation-de.json',
    'components.formItemComponents.Structure': 'translation-de.json',
    'components.formItemComponents.VerticalSelect': 'translation-de.json',
    'components.formItemComponents.YearSelect': 'translation-de.json',
    'components.katalComponents.CountrySelect': 'translation-de.json',
    'components.katalComponents.DocumentTable': 'translation-de.json',
    'components.katalComponents.DocumentUploader': 'translation-de.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-de.json',
    'components.modalComponents.EditDocModal': 'translation-de.json',
    'libs.downloadToCsv': 'translation-de.json',
    shared: 'translation-de.json',
    'types.client.enums.ProductType': 'translation-de.json',
    'types.document.enums.DocumentTag': 'translation-de.json',
    'types.finance.enums.ChargeCode': 'translation-de.json',
    'types.finance.enums.ReviewStatus': 'translation-de.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-de.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-de.json',
    'types.notification.enum.NotificationOptions': 'translation-de.json',
    'types.party.enums.BrokerRejectionReason': 'translation-de.json',
    'types.party.enums.Structure': 'translation-de.json',
    'types.shared.enums.LoadType': 'translation-de.json',
    'types.shared.enums.SkillLevel': 'translation-de.json',
    'types.shared.enums.SubscriptionPlan': 'translation-de.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-de.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-de.json',
    'types.ui.enums.WorkOrderTab': 'translation-de.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-de.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-de.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-de.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-de.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-de.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-de.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-de.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-de.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-de.json',
    'types.workOrder.enums.WorkOrderType': 'translation-de.json',
  },
  'de-DE': {
    'components.AccountingPointOfContacts': 'translation-de.json',
    'components.ActivationInfo': 'translation-de.json',
    'components.AppHeader': 'translation-de.json',
    'components.AppRecord': 'translation-de.json',
    'components.AppSiderMenu': 'translation-de.json',
    'components.AppTable': 'translation-de.json',
    'components.AssignWorkOrderTaskModal': 'translation-de.json',
    'components.AssignmentTeam': 'translation-de.json',
    'components.AssignmentWorkspace': 'translation-de.json',
    'components.CancelShipmentModal': 'translation-de.json',
    'components.CommodityGroup': 'translation-de.json',
    'components.CreateWorkOrderModal': 'translation-de.json',
    'components.CustomizeTableDrawer': 'translation-de.json',
    'components.DocumentsPage': 'translation-de.json',
    'components.Emails': 'translation-de.json',
    'components.EuIorActivation': 'translation-de.json',
    'components.ImporterEntityActivationSummary': 'translation-de.json',
    'components.InltCharges': 'translation-de.json',
    'components.Invoice': 'translation-de.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-de.json',
    'components.Milestones': 'translation-de.json',
    'components.MilestonesTab': 'translation-de.json',
    'components.NewBundleAvailable': 'translation-de.json',
    'components.Party': 'translation-de.json',
    'components.PasswordHelper': 'translation-de.json',
    'components.PayableInvoiceSummaryForm': 'translation-de.json',
    'components.PersonalInfo': 'translation-de.json',
    'components.ProfileForm': 'translation-de.json',
    'components.RecordSelect': 'translation-de.json',
    'components.RecordTable': 'translation-de.json',
    'components.RejectIorActivationModal': 'translation-de.json',
    'components.Shipment': 'translation-de.json',
    'components.ShipmentInfo': 'translation-de.json',
    'components.ShipperSelect': 'translation-de.json',
    'components.UnplannedCharges': 'translation-de.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-de.json',
    'components.User': 'translation-de.json',
    'components.WorkOrder': 'translation-de.json',
    'components.WorkOrderTabs': 'translation-de.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-de.json',
    'components.WorkOrderTaskInfoCard': 'translation-de.json',
    'components.WorkOrderTaskSteps': 'translation-de.json',
    'components.WorkOrderTasks': 'translation-de.json',
    'components.buttonComponents.MinusButton': 'translation-de.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-de.json',
    'components.formComponents.EuIorInfoCard': 'translation-de.json',
    'components.formComponents.LocationForm': 'translation-de.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-de.json',
    'components.formComponents.PartyFormBase': 'translation-de.json',
    'components.formComponents.PointOfContact': 'translation-de.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-de.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-de.json',
    'components.formComponents.UsCbpInfoCard': 'translation-de.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-de.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-de.json',
    'components.formItemComponents.DUNSNumber': 'translation-de.json',
    'components.formItemComponents.Email': 'translation-de.json',
    'components.formItemComponents.IORNumber': 'translation-de.json',
    'components.formItemComponents.PhoneNumber': 'translation-de.json',
    'components.formItemComponents.SimpleSelect': 'translation-de.json',
    'components.formItemComponents.Structure': 'translation-de.json',
    'components.formItemComponents.VerticalSelect': 'translation-de.json',
    'components.formItemComponents.YearSelect': 'translation-de.json',
    'components.katalComponents.CountrySelect': 'translation-de.json',
    'components.katalComponents.DocumentTable': 'translation-de.json',
    'components.katalComponents.DocumentUploader': 'translation-de.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-de.json',
    'components.modalComponents.EditDocModal': 'translation-de.json',
    'libs.downloadToCsv': 'translation-de.json',
    shared: 'translation-de.json',
    'types.client.enums.ProductType': 'translation-de.json',
    'types.document.enums.DocumentTag': 'translation-de.json',
    'types.finance.enums.ChargeCode': 'translation-de.json',
    'types.finance.enums.ReviewStatus': 'translation-de.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-de.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-de.json',
    'types.notification.enum.NotificationOptions': 'translation-de.json',
    'types.party.enums.BrokerRejectionReason': 'translation-de.json',
    'types.party.enums.Structure': 'translation-de.json',
    'types.shared.enums.LoadType': 'translation-de.json',
    'types.shared.enums.SkillLevel': 'translation-de.json',
    'types.shared.enums.SubscriptionPlan': 'translation-de.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-de.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-de.json',
    'types.ui.enums.WorkOrderTab': 'translation-de.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-de.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-de.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-de.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-de.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-de.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-de.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-de.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-de.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-de.json',
    'types.workOrder.enums.WorkOrderType': 'translation-de.json',
  },
  en: {
    'components.AccountingPointOfContacts': 'translation-en.json',
    'components.ActivationInfo': 'translation-en.json',
    'components.AppHeader': 'translation-en.json',
    'components.AppRecord': 'translation-en.json',
    'components.AppSiderMenu': 'translation-en.json',
    'components.AppTable': 'translation-en.json',
    'components.AssignWorkOrderTaskModal': 'translation-en.json',
    'components.AssignmentTeam': 'translation-en.json',
    'components.AssignmentWorkspace': 'translation-en.json',
    'components.CancelShipmentModal': 'translation-en.json',
    'components.CancelWorkOrderModal': 'translation-en.json',
    'components.CommodityGroup': 'translation-en.json',
    'components.CreateWorkOrderModal': 'translation-en.json',
    'components.CustomizeTableDrawer': 'translation-en.json',
    'components.DocumentsPage': 'translation-en.json',
    'components.Emails': 'translation-en.json',
    'components.EuIorActivation': 'translation-en.json',
    'components.ImporterEntityActivationSummary': 'translation-en.json',
    'components.InltCharges': 'translation-en.json',
    'components.Invoice': 'translation-en.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-en.json',
    'components.Milestones': 'translation-en.json',
    'components.MilestonesTab': 'translation-en.json',
    'components.NewBundleAvailable': 'translation-en.json',
    'components.Party': 'translation-en.json',
    'components.PasswordHelper': 'translation-en.json',
    'components.PayableInvoiceSummaryForm': 'translation-en.json',
    'components.PersonalInfo': 'translation-en.json',
    'components.ProfileForm': 'translation-en.json',
    'components.RecordSelect': 'translation-en.json',
    'components.RecordTable': 'translation-en.json',
    'components.RejectIorActivationModal': 'translation-en.json',
    'components.Shipment': 'translation-en.json',
    'components.ShipmentInfo': 'translation-en.json',
    'components.ShipperSelect': 'translation-en.json',
    'components.UnplannedCharges': 'translation-en.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-en.json',
    'components.User': 'translation-en.json',
    'components.WorkOrder': 'translation-en.json',
    'components.WorkOrderTabs': 'translation-en.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-en.json',
    'components.WorkOrderTaskInfoCard': 'translation-en.json',
    'components.WorkOrderTaskSteps': 'translation-en.json',
    'components.WorkOrderTasks': 'translation-en.json',
    'components.buttonComponents.MinusButton': 'translation-en.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-en.json',
    'components.formComponents.EuIorInfoCard': 'translation-en.json',
    'components.formComponents.LocationForm': 'translation-en.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-en.json',
    'components.formComponents.PartyFormBase': 'translation-en.json',
    'components.formComponents.PointOfContact': 'translation-en.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-en.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-en.json',
    'components.formComponents.UsCbpInfoCard': 'translation-en.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-en.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-en.json',
    'components.formItemComponents.DUNSNumber': 'translation-en.json',
    'components.formItemComponents.Email': 'translation-en.json',
    'components.formItemComponents.IORNumber': 'translation-en.json',
    'components.formItemComponents.PhoneNumber': 'translation-en.json',
    'components.formItemComponents.SimpleSelect': 'translation-en.json',
    'components.formItemComponents.Structure': 'translation-en.json',
    'components.formItemComponents.VerticalSelect': 'translation-en.json',
    'components.formItemComponents.YearSelect': 'translation-en.json',
    'components.katalComponents.CountrySelect': 'translation-en.json',
    'components.katalComponents.DocumentTable': 'translation-en.json',
    'components.katalComponents.DocumentTagModal': 'translation-en.json',
    'components.katalComponents.DocumentUploader': 'translation-en.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-en.json',
    'components.modalComponents.EditDocModal': 'translation-en.json',
    'libs.downloadToCsv': 'translation-en.json',
    shared: 'translation-en.json',
    'types.client.enums.ProductType': 'translation-en.json',
    'types.document.enums.DocumentTag': 'translation-en.json',
    'types.finance.enums.ChargeCode': 'translation-en.json',
    'types.finance.enums.ReviewStatus': 'translation-en.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-en.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-en.json',
    'types.notification.enum.NotificationOptions': 'translation-en.json',
    'types.party.enums.BrokerRejectionReason': 'translation-en.json',
    'types.party.enums.Structure': 'translation-en.json',
    'types.shared.enums.LoadType': 'translation-en.json',
    'types.shared.enums.RecordName': 'translation-en.json',
    'types.shared.enums.SkillLevel': 'translation-en.json',
    'types.shared.enums.SubscriptionPlan': 'translation-en.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-en.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-en.json',
    'types.ui.enums.WorkOrderTab': 'translation-en.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-en.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-en.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-en.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-en.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-en.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-en.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-en.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-en.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-en.json',
    'types.workOrder.enums.WorkOrderType': 'translation-en.json',
  },
  'en-GB': {
    'components.AccountingPointOfContacts': 'translation-en.json',
    'components.ActivationInfo': 'translation-en.json',
    'components.AppHeader': 'translation-en.json',
    'components.AppRecord': 'translation-en.json',
    'components.AppSiderMenu': 'translation-en.json',
    'components.AppTable': 'translation-en.json',
    'components.AssignWorkOrderTaskModal': 'translation-en.json',
    'components.AssignmentTeam': 'translation-en.json',
    'components.AssignmentWorkspace': 'translation-en.json',
    'components.CancelShipmentModal': 'translation-en.json',
    'components.CommodityGroup': 'translation-en.json',
    'components.CreateWorkOrderModal': 'translation-en.json',
    'components.CustomizeTableDrawer': 'translation-en.json',
    'components.DocumentsPage': 'translation-en.json',
    'components.Emails': 'translation-en.json',
    'components.EuIorActivation': 'translation-en.json',
    'components.ImporterEntityActivationSummary': 'translation-en.json',
    'components.InltCharges': 'translation-en.json',
    'components.Invoice': 'translation-en.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-en.json',
    'components.Milestones': 'translation-en.json',
    'components.MilestonesTab': 'translation-en.json',
    'components.NewBundleAvailable': 'translation-en.json',
    'components.Party': 'translation-en.json',
    'components.PasswordHelper': 'translation-en.json',
    'components.PayableInvoiceSummaryForm': 'translation-en.json',
    'components.PersonalInfo': 'translation-en.json',
    'components.ProfileForm': 'translation-en.json',
    'components.RecordSelect': 'translation-en.json',
    'components.RecordTable': 'translation-en.json',
    'components.RejectIorActivationModal': 'translation-en.json',
    'components.Shipment': 'translation-en.json',
    'components.ShipmentInfo': 'translation-en.json',
    'components.ShipperSelect': 'translation-en.json',
    'components.UnplannedCharges': 'translation-en.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-en.json',
    'components.User': 'translation-en.json',
    'components.WorkOrder': 'translation-en.json',
    'components.WorkOrderTabs': 'translation-en.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-en.json',
    'components.WorkOrderTaskInfoCard': 'translation-en.json',
    'components.WorkOrderTaskSteps': 'translation-en.json',
    'components.WorkOrderTasks': 'translation-en.json',
    'components.buttonComponents.MinusButton': 'translation-en.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-en.json',
    'components.formComponents.EuIorInfoCard': 'translation-en.json',
    'components.formComponents.LocationForm': 'translation-en.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-en.json',
    'components.formComponents.PartyFormBase': 'translation-en.json',
    'components.formComponents.PointOfContact': 'translation-en.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-en.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-en.json',
    'components.formComponents.UsCbpInfoCard': 'translation-en.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-en.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-en.json',
    'components.formItemComponents.DUNSNumber': 'translation-en.json',
    'components.formItemComponents.Email': 'translation-en.json',
    'components.formItemComponents.IORNumber': 'translation-en.json',
    'components.formItemComponents.PhoneNumber': 'translation-en.json',
    'components.formItemComponents.SimpleSelect': 'translation-en.json',
    'components.formItemComponents.Structure': 'translation-en.json',
    'components.formItemComponents.VerticalSelect': 'translation-en.json',
    'components.formItemComponents.YearSelect': 'translation-en.json',
    'components.katalComponents.CountrySelect': 'translation-en.json',
    'components.katalComponents.DocumentTable': 'translation-en.json',
    'components.katalComponents.DocumentTagModal': 'translation-en.json',
    'components.katalComponents.DocumentUploader': 'translation-en.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-en.json',
    'components.modalComponents.EditDocModal': 'translation-en.json',
    'libs.downloadToCsv': 'translation-en.json',
    shared: 'translation-en.json',
    'types.client.enums.ProductType': 'translation-en.json',
    'types.document.enums.DocumentTag': 'translation-en.json',
    'types.finance.enums.ChargeCode': 'translation-en.json',
    'types.finance.enums.ReviewStatus': 'translation-en.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-en.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-en.json',
    'types.notification.enum.NotificationOptions': 'translation-en.json',
    'types.party.enums.BrokerRejectionReason': 'translation-en.json',
    'types.party.enums.Structure': 'translation-en.json',
    'types.shared.enums.LoadType': 'translation-en.json',
    'types.shared.enums.RecordName': 'translation-en.json',
    'types.shared.enums.SkillLevel': 'translation-en.json',
    'types.shared.enums.SubscriptionPlan': 'translation-en.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-en.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-en.json',
    'types.ui.enums.WorkOrderTab': 'translation-en.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-en.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-en.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-en.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-en.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-en.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-en.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-en.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-en.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-en.json',
    'types.workOrder.enums.WorkOrderType': 'translation-en.json',
  },
  'en-US': {
    'components.AccountingPointOfContacts': 'translation-en.json',
    'components.ActivationInfo': 'translation-en.json',
    'components.AppHeader': 'translation-en.json',
    'components.AppRecord': 'translation-en.json',
    'components.AppSiderMenu': 'translation-en.json',
    'components.AppTable': 'translation-en.json',
    'components.AssignWorkOrderTaskModal': 'translation-en.json',
    'components.AssignmentTeam': 'translation-en.json',
    'components.AssignmentWorkspace': 'translation-en.json',
    'components.CancelShipmentModal': 'translation-en.json',
    'components.CancelWorkOrderModal': 'translation-en.json',
    'components.CommodityGroup': 'translation-en.json',
    'components.CreateWorkOrderModal': 'translation-en.json',
    'components.CustomizeTableDrawer': 'translation-en.json',
    'components.DocumentsPage': 'translation-en.json',
    'components.Emails': 'translation-en.json',
    'components.EuIorActivation': 'translation-en.json',
    'components.ImporterEntityActivationSummary': 'translation-en.json',
    'components.InltCharges': 'translation-en.json',
    'components.Invoice': 'translation-en.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-en.json',
    'components.Milestones': 'translation-en.json',
    'components.MilestonesTab': 'translation-en.json',
    'components.NewBundleAvailable': 'translation-en.json',
    'components.Party': 'translation-en.json',
    'components.PasswordHelper': 'translation-en.json',
    'components.PayableInvoiceSummaryForm': 'translation-en.json',
    'components.PersonalInfo': 'translation-en.json',
    'components.ProfileForm': 'translation-en.json',
    'components.RecordSelect': 'translation-en.json',
    'components.RecordTable': 'translation-en.json',
    'components.RejectIorActivationModal': 'translation-en.json',
    'components.Shipment': 'translation-en.json',
    'components.ShipmentInfo': 'translation-en.json',
    'components.ShipperSelect': 'translation-en.json',
    'components.UnplannedCharges': 'translation-en.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-en.json',
    'components.User': 'translation-en.json',
    'components.WorkOrder': 'translation-en.json',
    'components.WorkOrderTabs': 'translation-en.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-en.json',
    'components.WorkOrderTaskInfoCard': 'translation-en.json',
    'components.WorkOrderTaskSteps': 'translation-en.json',
    'components.WorkOrderTasks': 'translation-en.json',
    'components.buttonComponents.MinusButton': 'translation-en.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-en.json',
    'components.formComponents.EuIorInfoCard': 'translation-en.json',
    'components.formComponents.LocationForm': 'translation-en.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-en.json',
    'components.formComponents.PartyFormBase': 'translation-en.json',
    'components.formComponents.PointOfContact': 'translation-en.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-en.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-en.json',
    'components.formComponents.UsCbpInfoCard': 'translation-en.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-en.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-en.json',
    'components.formItemComponents.DUNSNumber': 'translation-en.json',
    'components.formItemComponents.Email': 'translation-en.json',
    'components.formItemComponents.IORNumber': 'translation-en.json',
    'components.formItemComponents.PhoneNumber': 'translation-en.json',
    'components.formItemComponents.SimpleSelect': 'translation-en.json',
    'components.formItemComponents.Structure': 'translation-en.json',
    'components.formItemComponents.VerticalSelect': 'translation-en.json',
    'components.formItemComponents.YearSelect': 'translation-en.json',
    'components.katalComponents.CountrySelect': 'translation-en.json',
    'components.katalComponents.DocumentTable': 'translation-en.json',
    'components.katalComponents.DocumentTagModal': 'translation-en.json',
    'components.katalComponents.DocumentUploader': 'translation-en.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-en.json',
    'components.modalComponents.EditDocModal': 'translation-en.json',
    'libs.downloadToCsv': 'translation-en.json',
    shared: 'translation-en.json',
    'types.client.enums.ProductType': 'translation-en.json',
    'types.document.enums.DocumentTag': 'translation-en.json',
    'types.finance.enums.ChargeCode': 'translation-en.json',
    'types.finance.enums.ReviewStatus': 'translation-en.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-en.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-en.json',
    'types.notification.enum.NotificationOptions': 'translation-en.json',
    'types.party.enums.BrokerRejectionReason': 'translation-en.json',
    'types.party.enums.Structure': 'translation-en.json',
    'types.shared.enums.LoadType': 'translation-en.json',
    'types.shared.enums.RecordName': 'translation-en.json',
    'types.shared.enums.SkillLevel': 'translation-en.json',
    'types.shared.enums.SubscriptionPlan': 'translation-en.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-en.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-en.json',
    'types.ui.enums.WorkOrderTab': 'translation-en.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-en.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-en.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-en.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-en.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-en.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-en.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-en.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-en.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-en.json',
    'types.workOrder.enums.WorkOrderType': 'translation-en.json',
  },
  es: {
    'components.AccountingPointOfContacts': 'translation-es.json',
    'components.ActivationInfo': 'translation-es.json',
    'components.AppHeader': 'translation-es.json',
    'components.AppRecord': 'translation-es.json',
    'components.AppSiderMenu': 'translation-es.json',
    'components.AppTable': 'translation-es.json',
    'components.AssignWorkOrderTaskModal': 'translation-es.json',
    'components.AssignmentTeam': 'translation-es.json',
    'components.AssignmentWorkspace': 'translation-es.json',
    'components.CancelShipmentModal': 'translation-es.json',
    'components.CommodityGroup': 'translation-es.json',
    'components.CreateWorkOrderModal': 'translation-es.json',
    'components.CustomizeTableDrawer': 'translation-es.json',
    'components.DocumentsPage': 'translation-es.json',
    'components.Emails': 'translation-es.json',
    'components.EuIorActivation': 'translation-es.json',
    'components.ImporterEntityActivationSummary': 'translation-es.json',
    'components.InltCharges': 'translation-es.json',
    'components.Invoice': 'translation-es.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-es.json',
    'components.Milestones': 'translation-es.json',
    'components.MilestonesTab': 'translation-es.json',
    'components.NewBundleAvailable': 'translation-es.json',
    'components.Party': 'translation-es.json',
    'components.PasswordHelper': 'translation-es.json',
    'components.PayableInvoiceSummaryForm': 'translation-es.json',
    'components.PersonalInfo': 'translation-es.json',
    'components.ProfileForm': 'translation-es.json',
    'components.RecordSelect': 'translation-es.json',
    'components.RecordTable': 'translation-es.json',
    'components.RejectIorActivationModal': 'translation-es.json',
    'components.Shipment': 'translation-es.json',
    'components.ShipmentInfo': 'translation-es.json',
    'components.ShipperSelect': 'translation-es.json',
    'components.UnplannedCharges': 'translation-es.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-es.json',
    'components.User': 'translation-es.json',
    'components.WorkOrder': 'translation-es.json',
    'components.WorkOrderTabs': 'translation-es.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-es.json',
    'components.WorkOrderTaskInfoCard': 'translation-es.json',
    'components.WorkOrderTaskSteps': 'translation-es.json',
    'components.WorkOrderTasks': 'translation-es.json',
    'components.buttonComponents.MinusButton': 'translation-es.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-es.json',
    'components.formComponents.EuIorInfoCard': 'translation-es.json',
    'components.formComponents.LocationForm': 'translation-es.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-es.json',
    'components.formComponents.PartyFormBase': 'translation-es.json',
    'components.formComponents.PointOfContact': 'translation-es.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-es.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-es.json',
    'components.formComponents.UsCbpInfoCard': 'translation-es.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-es.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-es.json',
    'components.formItemComponents.DUNSNumber': 'translation-es.json',
    'components.formItemComponents.Email': 'translation-es.json',
    'components.formItemComponents.IORNumber': 'translation-es.json',
    'components.formItemComponents.PhoneNumber': 'translation-es.json',
    'components.formItemComponents.SimpleSelect': 'translation-es.json',
    'components.formItemComponents.Structure': 'translation-es.json',
    'components.formItemComponents.VerticalSelect': 'translation-es.json',
    'components.formItemComponents.YearSelect': 'translation-es.json',
    'components.katalComponents.CountrySelect': 'translation-es.json',
    'components.katalComponents.DocumentTable': 'translation-es.json',
    'components.katalComponents.DocumentTagModal': 'translation-es.json',
    'components.katalComponents.DocumentUploader': 'translation-es.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-es.json',
    'components.modalComponents.EditDocModal': 'translation-es.json',
    'libs.downloadToCsv': 'translation-es.json',
    shared: 'translation-es.json',
    'types.client.enums.ProductType': 'translation-es.json',
    'types.document.enums.DocumentTag': 'translation-es.json',
    'types.finance.enums.ChargeCode': 'translation-es.json',
    'types.finance.enums.ReviewStatus': 'translation-es.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-es.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-es.json',
    'types.notification.enum.NotificationOptions': 'translation-es.json',
    'types.party.enums.BrokerRejectionReason': 'translation-es.json',
    'types.party.enums.Structure': 'translation-es.json',
    'types.shared.enums.LoadType': 'translation-es.json',
    'types.shared.enums.RecordName': 'translation-es.json',
    'types.shared.enums.SkillLevel': 'translation-es.json',
    'types.shared.enums.SubscriptionPlan': 'translation-es.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-es.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-es.json',
    'types.ui.enums.WorkOrderTab': 'translation-es.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-es.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-es.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-es.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-es.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-es.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-es.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-es.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-es.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-es.json',
    'types.workOrder.enums.WorkOrderType': 'translation-es.json',
  },
  'es-ES': {
    'components.AccountingPointOfContacts': 'translation-es.json',
    'components.ActivationInfo': 'translation-es.json',
    'components.AppHeader': 'translation-es.json',
    'components.AppRecord': 'translation-es.json',
    'components.AppSiderMenu': 'translation-es.json',
    'components.AppTable': 'translation-es.json',
    'components.AssignWorkOrderTaskModal': 'translation-es.json',
    'components.AssignmentTeam': 'translation-es.json',
    'components.AssignmentWorkspace': 'translation-es.json',
    'components.CancelShipmentModal': 'translation-es.json',
    'components.CommodityGroup': 'translation-es.json',
    'components.CreateWorkOrderModal': 'translation-es.json',
    'components.CustomizeTableDrawer': 'translation-es.json',
    'components.DocumentsPage': 'translation-es.json',
    'components.Emails': 'translation-es.json',
    'components.EuIorActivation': 'translation-es.json',
    'components.ImporterEntityActivationSummary': 'translation-es.json',
    'components.InltCharges': 'translation-es.json',
    'components.Invoice': 'translation-es.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-es.json',
    'components.Milestones': 'translation-es.json',
    'components.MilestonesTab': 'translation-es.json',
    'components.NewBundleAvailable': 'translation-es.json',
    'components.Party': 'translation-es.json',
    'components.PasswordHelper': 'translation-es.json',
    'components.PayableInvoiceSummaryForm': 'translation-es.json',
    'components.PersonalInfo': 'translation-es.json',
    'components.ProfileForm': 'translation-es.json',
    'components.RecordSelect': 'translation-es.json',
    'components.RecordTable': 'translation-es.json',
    'components.RejectIorActivationModal': 'translation-es.json',
    'components.Shipment': 'translation-es.json',
    'components.ShipmentInfo': 'translation-es.json',
    'components.ShipperSelect': 'translation-es.json',
    'components.UnplannedCharges': 'translation-es.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-es.json',
    'components.User': 'translation-es.json',
    'components.WorkOrder': 'translation-es.json',
    'components.WorkOrderTabs': 'translation-es.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-es.json',
    'components.WorkOrderTaskInfoCard': 'translation-es.json',
    'components.WorkOrderTaskSteps': 'translation-es.json',
    'components.WorkOrderTasks': 'translation-es.json',
    'components.buttonComponents.MinusButton': 'translation-es.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-es.json',
    'components.formComponents.EuIorInfoCard': 'translation-es.json',
    'components.formComponents.LocationForm': 'translation-es.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-es.json',
    'components.formComponents.PartyFormBase': 'translation-es.json',
    'components.formComponents.PointOfContact': 'translation-es.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-es.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-es.json',
    'components.formComponents.UsCbpInfoCard': 'translation-es.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-es.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-es.json',
    'components.formItemComponents.DUNSNumber': 'translation-es.json',
    'components.formItemComponents.Email': 'translation-es.json',
    'components.formItemComponents.IORNumber': 'translation-es.json',
    'components.formItemComponents.PhoneNumber': 'translation-es.json',
    'components.formItemComponents.SimpleSelect': 'translation-es.json',
    'components.formItemComponents.Structure': 'translation-es.json',
    'components.formItemComponents.VerticalSelect': 'translation-es.json',
    'components.formItemComponents.YearSelect': 'translation-es.json',
    'components.katalComponents.CountrySelect': 'translation-es.json',
    'components.katalComponents.DocumentTable': 'translation-es.json',
    'components.katalComponents.DocumentTagModal': 'translation-es.json',
    'components.katalComponents.DocumentUploader': 'translation-es.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-es.json',
    'components.modalComponents.EditDocModal': 'translation-es.json',
    'libs.downloadToCsv': 'translation-es.json',
    shared: 'translation-es.json',
    'types.client.enums.ProductType': 'translation-es.json',
    'types.document.enums.DocumentTag': 'translation-es.json',
    'types.finance.enums.ChargeCode': 'translation-es.json',
    'types.finance.enums.ReviewStatus': 'translation-es.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-es.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-es.json',
    'types.notification.enum.NotificationOptions': 'translation-es.json',
    'types.party.enums.BrokerRejectionReason': 'translation-es.json',
    'types.party.enums.Structure': 'translation-es.json',
    'types.shared.enums.LoadType': 'translation-es.json',
    'types.shared.enums.RecordName': 'translation-es.json',
    'types.shared.enums.SkillLevel': 'translation-es.json',
    'types.shared.enums.SubscriptionPlan': 'translation-es.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-es.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-es.json',
    'types.ui.enums.WorkOrderTab': 'translation-es.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-es.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-es.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-es.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-es.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-es.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-es.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-es.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-es.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-es.json',
    'types.workOrder.enums.WorkOrderType': 'translation-es.json',
  },
  fr: {
    'components.AccountingPointOfContacts': 'translation-fr.json',
    'components.ActivationInfo': 'translation-fr.json',
    'components.AppHeader': 'translation-fr.json',
    'components.AppRecord': 'translation-fr.json',
    'components.AppSiderMenu': 'translation-fr.json',
    'components.AppTable': 'translation-fr.json',
    'components.AssignWorkOrderTaskModal': 'translation-fr.json',
    'components.AssignmentTeam': 'translation-fr.json',
    'components.AssignmentWorkspace': 'translation-fr.json',
    'components.CancelShipmentModal': 'translation-fr.json',
    'components.CommodityGroup': 'translation-fr.json',
    'components.CreateWorkOrderModal': 'translation-fr.json',
    'components.CustomizeTableDrawer': 'translation-fr.json',
    'components.DocumentsPage': 'translation-fr.json',
    'components.Emails': 'translation-fr.json',
    'components.EuIorActivation': 'translation-fr.json',
    'components.ImporterEntityActivationSummary': 'translation-fr.json',
    'components.InltCharges': 'translation-fr.json',
    'components.Invoice': 'translation-fr.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-fr.json',
    'components.Milestones': 'translation-fr.json',
    'components.MilestonesTab': 'translation-fr.json',
    'components.NewBundleAvailable': 'translation-fr.json',
    'components.Party': 'translation-fr.json',
    'components.PasswordHelper': 'translation-fr.json',
    'components.PayableInvoiceSummaryForm': 'translation-fr.json',
    'components.PersonalInfo': 'translation-fr.json',
    'components.ProfileForm': 'translation-fr.json',
    'components.RecordSelect': 'translation-fr.json',
    'components.RecordTable': 'translation-fr.json',
    'components.RejectIorActivationModal': 'translation-fr.json',
    'components.Shipment': 'translation-fr.json',
    'components.ShipmentInfo': 'translation-fr.json',
    'components.ShipperSelect': 'translation-fr.json',
    'components.UnplannedCharges': 'translation-fr.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-fr.json',
    'components.User': 'translation-fr.json',
    'components.WorkOrder': 'translation-fr.json',
    'components.WorkOrderTabs': 'translation-fr.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-fr.json',
    'components.WorkOrderTaskInfoCard': 'translation-fr.json',
    'components.WorkOrderTaskSteps': 'translation-fr.json',
    'components.WorkOrderTasks': 'translation-fr.json',
    'components.buttonComponents.MinusButton': 'translation-fr.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-fr.json',
    'components.formComponents.EuIorInfoCard': 'translation-fr.json',
    'components.formComponents.LocationForm': 'translation-fr.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-fr.json',
    'components.formComponents.PartyFormBase': 'translation-fr.json',
    'components.formComponents.PointOfContact': 'translation-fr.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-fr.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-fr.json',
    'components.formComponents.UsCbpInfoCard': 'translation-fr.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-fr.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-fr.json',
    'components.formItemComponents.DUNSNumber': 'translation-fr.json',
    'components.formItemComponents.Email': 'translation-fr.json',
    'components.formItemComponents.IORNumber': 'translation-fr.json',
    'components.formItemComponents.PhoneNumber': 'translation-fr.json',
    'components.formItemComponents.SimpleSelect': 'translation-fr.json',
    'components.formItemComponents.Structure': 'translation-fr.json',
    'components.formItemComponents.VerticalSelect': 'translation-fr.json',
    'components.formItemComponents.YearSelect': 'translation-fr.json',
    'components.katalComponents.CountrySelect': 'translation-fr.json',
    'components.katalComponents.DocumentTable': 'translation-fr.json',
    'components.katalComponents.DocumentTagModal': 'translation-fr.json',
    'components.katalComponents.DocumentUploader': 'translation-fr.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-fr.json',
    'components.modalComponents.EditDocModal': 'translation-fr.json',
    'libs.downloadToCsv': 'translation-fr.json',
    shared: 'translation-fr.json',
    'types.client.enums.ProductType': 'translation-fr.json',
    'types.document.enums.DocumentTag': 'translation-fr.json',
    'types.finance.enums.ChargeCode': 'translation-fr.json',
    'types.finance.enums.ReviewStatus': 'translation-fr.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-fr.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-fr.json',
    'types.notification.enum.NotificationOptions': 'translation-fr.json',
    'types.party.enums.BrokerRejectionReason': 'translation-fr.json',
    'types.party.enums.Structure': 'translation-fr.json',
    'types.shared.enums.LoadType': 'translation-fr.json',
    'types.shared.enums.RecordName': 'translation-fr.json',
    'types.shared.enums.SkillLevel': 'translation-fr.json',
    'types.shared.enums.SubscriptionPlan': 'translation-fr.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-fr.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-fr.json',
    'types.ui.enums.WorkOrderTab': 'translation-fr.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-fr.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-fr.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-fr.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderType': 'translation-fr.json',
  },
  'fr-FR': {
    'components.AccountingPointOfContacts': 'translation-fr.json',
    'components.ActivationInfo': 'translation-fr.json',
    'components.AppHeader': 'translation-fr.json',
    'components.AppRecord': 'translation-fr.json',
    'components.AppSiderMenu': 'translation-fr.json',
    'components.AppTable': 'translation-fr.json',
    'components.AssignWorkOrderTaskModal': 'translation-fr.json',
    'components.AssignmentTeam': 'translation-fr.json',
    'components.AssignmentWorkspace': 'translation-fr.json',
    'components.CancelShipmentModal': 'translation-fr.json',
    'components.CommodityGroup': 'translation-fr.json',
    'components.CreateWorkOrderModal': 'translation-fr.json',
    'components.CustomizeTableDrawer': 'translation-fr.json',
    'components.DocumentsPage': 'translation-fr.json',
    'components.Emails': 'translation-fr.json',
    'components.EuIorActivation': 'translation-fr.json',
    'components.ImporterEntityActivationSummary': 'translation-fr.json',
    'components.InltCharges': 'translation-fr.json',
    'components.Invoice': 'translation-fr.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-fr.json',
    'components.Milestones': 'translation-fr.json',
    'components.MilestonesTab': 'translation-fr.json',
    'components.NewBundleAvailable': 'translation-fr.json',
    'components.Party': 'translation-fr.json',
    'components.PasswordHelper': 'translation-fr.json',
    'components.PayableInvoiceSummaryForm': 'translation-fr.json',
    'components.PersonalInfo': 'translation-fr.json',
    'components.ProfileForm': 'translation-fr.json',
    'components.RecordSelect': 'translation-fr.json',
    'components.RecordTable': 'translation-fr.json',
    'components.RejectIorActivationModal': 'translation-fr.json',
    'components.Shipment': 'translation-fr.json',
    'components.ShipmentInfo': 'translation-fr.json',
    'components.ShipperSelect': 'translation-fr.json',
    'components.UnplannedCharges': 'translation-fr.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-fr.json',
    'components.User': 'translation-fr.json',
    'components.WorkOrder': 'translation-fr.json',
    'components.WorkOrderTabs': 'translation-fr.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-fr.json',
    'components.WorkOrderTaskInfoCard': 'translation-fr.json',
    'components.WorkOrderTaskSteps': 'translation-fr.json',
    'components.WorkOrderTasks': 'translation-fr.json',
    'components.buttonComponents.MinusButton': 'translation-fr.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-fr.json',
    'components.formComponents.EuIorInfoCard': 'translation-fr.json',
    'components.formComponents.LocationForm': 'translation-fr.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-fr.json',
    'components.formComponents.PartyFormBase': 'translation-fr.json',
    'components.formComponents.PointOfContact': 'translation-fr.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-fr.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-fr.json',
    'components.formComponents.UsCbpInfoCard': 'translation-fr.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-fr.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-fr.json',
    'components.formItemComponents.DUNSNumber': 'translation-fr.json',
    'components.formItemComponents.Email': 'translation-fr.json',
    'components.formItemComponents.IORNumber': 'translation-fr.json',
    'components.formItemComponents.PhoneNumber': 'translation-fr.json',
    'components.formItemComponents.SimpleSelect': 'translation-fr.json',
    'components.formItemComponents.Structure': 'translation-fr.json',
    'components.formItemComponents.VerticalSelect': 'translation-fr.json',
    'components.formItemComponents.YearSelect': 'translation-fr.json',
    'components.katalComponents.CountrySelect': 'translation-fr.json',
    'components.katalComponents.DocumentTable': 'translation-fr.json',
    'components.katalComponents.DocumentTagModal': 'translation-fr.json',
    'components.katalComponents.DocumentUploader': 'translation-fr.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-fr.json',
    'components.modalComponents.EditDocModal': 'translation-fr.json',
    'libs.downloadToCsv': 'translation-fr.json',
    shared: 'translation-fr.json',
    'types.client.enums.ProductType': 'translation-fr.json',
    'types.document.enums.DocumentTag': 'translation-fr.json',
    'types.finance.enums.ChargeCode': 'translation-fr.json',
    'types.finance.enums.ReviewStatus': 'translation-fr.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-fr.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-fr.json',
    'types.notification.enum.NotificationOptions': 'translation-fr.json',
    'types.party.enums.BrokerRejectionReason': 'translation-fr.json',
    'types.party.enums.Structure': 'translation-fr.json',
    'types.shared.enums.LoadType': 'translation-fr.json',
    'types.shared.enums.RecordName': 'translation-fr.json',
    'types.shared.enums.SkillLevel': 'translation-fr.json',
    'types.shared.enums.SubscriptionPlan': 'translation-fr.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-fr.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-fr.json',
    'types.ui.enums.WorkOrderTab': 'translation-fr.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-fr.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-fr.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-fr.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-fr.json',
    'types.workOrder.enums.WorkOrderType': 'translation-fr.json',
  },
  hi: {
    'components.AccountingPointOfContacts': 'translation-hi.json',
    'components.ActivationInfo': 'translation-hi.json',
    'components.AppHeader': 'translation-hi.json',
    'components.AppRecord': 'translation-hi.json',
    'components.AppSiderMenu': 'translation-hi.json',
    'components.AppTable': 'translation-hi.json',
    'components.AssignWorkOrderTaskModal': 'translation-hi.json',
    'components.AssignmentTeam': 'translation-hi.json',
    'components.AssignmentWorkspace': 'translation-hi.json',
    'components.CancelShipmentModal': 'translation-hi.json',
    'components.CommodityGroup': 'translation-hi.json',
    'components.CreateWorkOrderModal': 'translation-hi.json',
    'components.CustomizeTableDrawer': 'translation-hi.json',
    'components.DocumentsPage': 'translation-hi.json',
    'components.Emails': 'translation-hi.json',
    'components.EuIorActivation': 'translation-hi.json',
    'components.ImporterEntityActivationSummary': 'translation-hi.json',
    'components.InltCharges': 'translation-hi.json',
    'components.Invoice': 'translation-hi.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-hi.json',
    'components.Milestones': 'translation-hi.json',
    'components.MilestonesTab': 'translation-hi.json',
    'components.NewBundleAvailable': 'translation-hi.json',
    'components.Party': 'translation-hi.json',
    'components.PasswordHelper': 'translation-hi.json',
    'components.PayableInvoiceSummaryForm': 'translation-hi.json',
    'components.PersonalInfo': 'translation-hi.json',
    'components.ProfileForm': 'translation-hi.json',
    'components.RecordSelect': 'translation-hi.json',
    'components.RecordTable': 'translation-hi.json',
    'components.RejectIorActivationModal': 'translation-hi.json',
    'components.Shipment': 'translation-hi.json',
    'components.ShipmentInfo': 'translation-hi.json',
    'components.ShipperSelect': 'translation-hi.json',
    'components.UnplannedCharges': 'translation-hi.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-hi.json',
    'components.User': 'translation-hi.json',
    'components.WorkOrder': 'translation-hi.json',
    'components.WorkOrderTabs': 'translation-hi.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-hi.json',
    'components.WorkOrderTaskInfoCard': 'translation-hi.json',
    'components.WorkOrderTaskSteps': 'translation-hi.json',
    'components.WorkOrderTasks': 'translation-hi.json',
    'components.buttonComponents.MinusButton': 'translation-hi.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-hi.json',
    'components.formComponents.EuIorInfoCard': 'translation-hi.json',
    'components.formComponents.LocationForm': 'translation-hi.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-hi.json',
    'components.formComponents.PartyFormBase': 'translation-hi.json',
    'components.formComponents.PointOfContact': 'translation-hi.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-hi.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-hi.json',
    'components.formComponents.UsCbpInfoCard': 'translation-hi.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-hi.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-hi.json',
    'components.formItemComponents.DUNSNumber': 'translation-hi.json',
    'components.formItemComponents.Email': 'translation-hi.json',
    'components.formItemComponents.IORNumber': 'translation-hi.json',
    'components.formItemComponents.PhoneNumber': 'translation-hi.json',
    'components.formItemComponents.SimpleSelect': 'translation-hi.json',
    'components.formItemComponents.Structure': 'translation-hi.json',
    'components.formItemComponents.VerticalSelect': 'translation-hi.json',
    'components.formItemComponents.YearSelect': 'translation-hi.json',
    'components.katalComponents.CountrySelect': 'translation-hi.json',
    'components.katalComponents.DocumentTable': 'translation-hi.json',
    'components.katalComponents.DocumentUploader': 'translation-hi.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-hi.json',
    'components.modalComponents.EditDocModal': 'translation-hi.json',
    'libs.downloadToCsv': 'translation-hi.json',
    shared: 'translation-hi.json',
    'types.client.enums.ProductType': 'translation-hi.json',
    'types.document.enums.DocumentTag': 'translation-hi.json',
    'types.finance.enums.ChargeCode': 'translation-hi.json',
    'types.finance.enums.ReviewStatus': 'translation-hi.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-hi.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-hi.json',
    'types.notification.enum.NotificationOptions': 'translation-hi.json',
    'types.party.enums.BrokerRejectionReason': 'translation-hi.json',
    'types.party.enums.Structure': 'translation-hi.json',
    'types.shared.enums.LoadType': 'translation-hi.json',
    'types.shared.enums.SkillLevel': 'translation-hi.json',
    'types.shared.enums.SubscriptionPlan': 'translation-hi.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-hi.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-hi.json',
    'types.ui.enums.WorkOrderTab': 'translation-hi.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-hi.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-hi.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-hi.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderType': 'translation-hi.json',
  },
  'hi-IN': {
    'components.AccountingPointOfContacts': 'translation-hi.json',
    'components.ActivationInfo': 'translation-hi.json',
    'components.AppHeader': 'translation-hi.json',
    'components.AppRecord': 'translation-hi.json',
    'components.AppSiderMenu': 'translation-hi.json',
    'components.AppTable': 'translation-hi.json',
    'components.AssignWorkOrderTaskModal': 'translation-hi.json',
    'components.AssignmentTeam': 'translation-hi.json',
    'components.AssignmentWorkspace': 'translation-hi.json',
    'components.CancelShipmentModal': 'translation-hi.json',
    'components.CommodityGroup': 'translation-hi.json',
    'components.CreateWorkOrderModal': 'translation-hi.json',
    'components.CustomizeTableDrawer': 'translation-hi.json',
    'components.DocumentsPage': 'translation-hi.json',
    'components.Emails': 'translation-hi.json',
    'components.EuIorActivation': 'translation-hi.json',
    'components.ImporterEntityActivationSummary': 'translation-hi.json',
    'components.InltCharges': 'translation-hi.json',
    'components.Invoice': 'translation-hi.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-hi.json',
    'components.Milestones': 'translation-hi.json',
    'components.MilestonesTab': 'translation-hi.json',
    'components.NewBundleAvailable': 'translation-hi.json',
    'components.Party': 'translation-hi.json',
    'components.PasswordHelper': 'translation-hi.json',
    'components.PayableInvoiceSummaryForm': 'translation-hi.json',
    'components.PersonalInfo': 'translation-hi.json',
    'components.ProfileForm': 'translation-hi.json',
    'components.RecordSelect': 'translation-hi.json',
    'components.RecordTable': 'translation-hi.json',
    'components.RejectIorActivationModal': 'translation-hi.json',
    'components.Shipment': 'translation-hi.json',
    'components.ShipmentInfo': 'translation-hi.json',
    'components.ShipperSelect': 'translation-hi.json',
    'components.UnplannedCharges': 'translation-hi.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-hi.json',
    'components.User': 'translation-hi.json',
    'components.WorkOrder': 'translation-hi.json',
    'components.WorkOrderTabs': 'translation-hi.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-hi.json',
    'components.WorkOrderTaskInfoCard': 'translation-hi.json',
    'components.WorkOrderTaskSteps': 'translation-hi.json',
    'components.WorkOrderTasks': 'translation-hi.json',
    'components.buttonComponents.MinusButton': 'translation-hi.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-hi.json',
    'components.formComponents.EuIorInfoCard': 'translation-hi.json',
    'components.formComponents.LocationForm': 'translation-hi.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-hi.json',
    'components.formComponents.PartyFormBase': 'translation-hi.json',
    'components.formComponents.PointOfContact': 'translation-hi.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-hi.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-hi.json',
    'components.formComponents.UsCbpInfoCard': 'translation-hi.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-hi.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-hi.json',
    'components.formItemComponents.DUNSNumber': 'translation-hi.json',
    'components.formItemComponents.Email': 'translation-hi.json',
    'components.formItemComponents.IORNumber': 'translation-hi.json',
    'components.formItemComponents.PhoneNumber': 'translation-hi.json',
    'components.formItemComponents.SimpleSelect': 'translation-hi.json',
    'components.formItemComponents.Structure': 'translation-hi.json',
    'components.formItemComponents.VerticalSelect': 'translation-hi.json',
    'components.formItemComponents.YearSelect': 'translation-hi.json',
    'components.katalComponents.CountrySelect': 'translation-hi.json',
    'components.katalComponents.DocumentTable': 'translation-hi.json',
    'components.katalComponents.DocumentUploader': 'translation-hi.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-hi.json',
    'components.modalComponents.EditDocModal': 'translation-hi.json',
    'libs.downloadToCsv': 'translation-hi.json',
    shared: 'translation-hi.json',
    'types.client.enums.ProductType': 'translation-hi.json',
    'types.document.enums.DocumentTag': 'translation-hi.json',
    'types.finance.enums.ChargeCode': 'translation-hi.json',
    'types.finance.enums.ReviewStatus': 'translation-hi.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-hi.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-hi.json',
    'types.notification.enum.NotificationOptions': 'translation-hi.json',
    'types.party.enums.BrokerRejectionReason': 'translation-hi.json',
    'types.party.enums.Structure': 'translation-hi.json',
    'types.shared.enums.LoadType': 'translation-hi.json',
    'types.shared.enums.SkillLevel': 'translation-hi.json',
    'types.shared.enums.SubscriptionPlan': 'translation-hi.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-hi.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-hi.json',
    'types.ui.enums.WorkOrderTab': 'translation-hi.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-hi.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-hi.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-hi.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-hi.json',
    'types.workOrder.enums.WorkOrderType': 'translation-hi.json',
  },
  ja: {
    'components.AccountingPointOfContacts': 'translation-ja.json',
    'components.ActivationInfo': 'translation-ja.json',
    'components.AppHeader': 'translation-ja.json',
    'components.AppRecord': 'translation-ja.json',
    'components.AppSiderMenu': 'translation-ja.json',
    'components.AppTable': 'translation-ja.json',
    'components.AssignWorkOrderTaskModal': 'translation-ja.json',
    'components.AssignmentTeam': 'translation-ja.json',
    'components.AssignmentWorkspace': 'translation-ja.json',
    'components.CancelShipmentModal': 'translation-ja.json',
    'components.CommodityGroup': 'translation-ja.json',
    'components.CreateWorkOrderModal': 'translation-ja.json',
    'components.CustomizeTableDrawer': 'translation-ja.json',
    'components.DocumentsPage': 'translation-ja.json',
    'components.Emails': 'translation-ja.json',
    'components.EuIorActivation': 'translation-ja.json',
    'components.ImporterEntityActivationSummary': 'translation-ja.json',
    'components.InltCharges': 'translation-ja.json',
    'components.Invoice': 'translation-ja.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-ja.json',
    'components.Milestones': 'translation-ja.json',
    'components.MilestonesTab': 'translation-ja.json',
    'components.NewBundleAvailable': 'translation-ja.json',
    'components.Party': 'translation-ja.json',
    'components.PasswordHelper': 'translation-ja.json',
    'components.PayableInvoiceSummaryForm': 'translation-ja.json',
    'components.PersonalInfo': 'translation-ja.json',
    'components.ProfileForm': 'translation-ja.json',
    'components.RecordSelect': 'translation-ja.json',
    'components.RecordTable': 'translation-ja.json',
    'components.RejectIorActivationModal': 'translation-ja.json',
    'components.Shipment': 'translation-ja.json',
    'components.ShipmentInfo': 'translation-ja.json',
    'components.ShipperSelect': 'translation-ja.json',
    'components.UnplannedCharges': 'translation-ja.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-ja.json',
    'components.User': 'translation-ja.json',
    'components.WorkOrder': 'translation-ja.json',
    'components.WorkOrderTabs': 'translation-ja.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-ja.json',
    'components.WorkOrderTaskInfoCard': 'translation-ja.json',
    'components.WorkOrderTaskSteps': 'translation-ja.json',
    'components.WorkOrderTasks': 'translation-ja.json',
    'components.buttonComponents.MinusButton': 'translation-ja.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-ja.json',
    'components.formComponents.EuIorInfoCard': 'translation-ja.json',
    'components.formComponents.LocationForm': 'translation-ja.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-ja.json',
    'components.formComponents.PartyFormBase': 'translation-ja.json',
    'components.formComponents.PointOfContact': 'translation-ja.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-ja.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-ja.json',
    'components.formComponents.UsCbpInfoCard': 'translation-ja.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-ja.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-ja.json',
    'components.formItemComponents.DUNSNumber': 'translation-ja.json',
    'components.formItemComponents.Email': 'translation-ja.json',
    'components.formItemComponents.IORNumber': 'translation-ja.json',
    'components.formItemComponents.PhoneNumber': 'translation-ja.json',
    'components.formItemComponents.SimpleSelect': 'translation-ja.json',
    'components.formItemComponents.Structure': 'translation-ja.json',
    'components.formItemComponents.VerticalSelect': 'translation-ja.json',
    'components.formItemComponents.YearSelect': 'translation-ja.json',
    'components.katalComponents.CountrySelect': 'translation-ja.json',
    'components.katalComponents.DocumentTable': 'translation-ja.json',
    'components.katalComponents.DocumentUploader': 'translation-ja.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-ja.json',
    'components.modalComponents.EditDocModal': 'translation-ja.json',
    'libs.downloadToCsv': 'translation-ja.json',
    shared: 'translation-ja.json',
    'types.client.enums.ProductType': 'translation-ja.json',
    'types.document.enums.DocumentTag': 'translation-ja.json',
    'types.finance.enums.ChargeCode': 'translation-ja.json',
    'types.finance.enums.ReviewStatus': 'translation-ja.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-ja.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-ja.json',
    'types.notification.enum.NotificationOptions': 'translation-ja.json',
    'types.party.enums.BrokerRejectionReason': 'translation-ja.json',
    'types.party.enums.Structure': 'translation-ja.json',
    'types.shared.enums.LoadType': 'translation-ja.json',
    'types.shared.enums.SkillLevel': 'translation-ja.json',
    'types.shared.enums.SubscriptionPlan': 'translation-ja.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-ja.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-ja.json',
    'types.ui.enums.WorkOrderTab': 'translation-ja.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-ja.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-ja.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-ja.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderType': 'translation-ja.json',
  },
  'ja-JP': {
    'components.AccountingPointOfContacts': 'translation-ja.json',
    'components.ActivationInfo': 'translation-ja.json',
    'components.AppHeader': 'translation-ja.json',
    'components.AppRecord': 'translation-ja.json',
    'components.AppSiderMenu': 'translation-ja.json',
    'components.AppTable': 'translation-ja.json',
    'components.AssignWorkOrderTaskModal': 'translation-ja.json',
    'components.AssignmentTeam': 'translation-ja.json',
    'components.AssignmentWorkspace': 'translation-ja.json',
    'components.CancelShipmentModal': 'translation-ja.json',
    'components.CommodityGroup': 'translation-ja.json',
    'components.CreateWorkOrderModal': 'translation-ja.json',
    'components.CustomizeTableDrawer': 'translation-ja.json',
    'components.DocumentsPage': 'translation-ja.json',
    'components.Emails': 'translation-ja.json',
    'components.EuIorActivation': 'translation-ja.json',
    'components.ImporterEntityActivationSummary': 'translation-ja.json',
    'components.InltCharges': 'translation-ja.json',
    'components.Invoice': 'translation-ja.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-ja.json',
    'components.Milestones': 'translation-ja.json',
    'components.MilestonesTab': 'translation-ja.json',
    'components.NewBundleAvailable': 'translation-ja.json',
    'components.Party': 'translation-ja.json',
    'components.PasswordHelper': 'translation-ja.json',
    'components.PayableInvoiceSummaryForm': 'translation-ja.json',
    'components.PersonalInfo': 'translation-ja.json',
    'components.ProfileForm': 'translation-ja.json',
    'components.RecordSelect': 'translation-ja.json',
    'components.RecordTable': 'translation-ja.json',
    'components.RejectIorActivationModal': 'translation-ja.json',
    'components.Shipment': 'translation-ja.json',
    'components.ShipmentInfo': 'translation-ja.json',
    'components.ShipperSelect': 'translation-ja.json',
    'components.UnplannedCharges': 'translation-ja.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-ja.json',
    'components.User': 'translation-ja.json',
    'components.WorkOrder': 'translation-ja.json',
    'components.WorkOrderTabs': 'translation-ja.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-ja.json',
    'components.WorkOrderTaskInfoCard': 'translation-ja.json',
    'components.WorkOrderTaskSteps': 'translation-ja.json',
    'components.WorkOrderTasks': 'translation-ja.json',
    'components.buttonComponents.MinusButton': 'translation-ja.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-ja.json',
    'components.formComponents.EuIorInfoCard': 'translation-ja.json',
    'components.formComponents.LocationForm': 'translation-ja.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-ja.json',
    'components.formComponents.PartyFormBase': 'translation-ja.json',
    'components.formComponents.PointOfContact': 'translation-ja.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-ja.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-ja.json',
    'components.formComponents.UsCbpInfoCard': 'translation-ja.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-ja.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-ja.json',
    'components.formItemComponents.DUNSNumber': 'translation-ja.json',
    'components.formItemComponents.Email': 'translation-ja.json',
    'components.formItemComponents.IORNumber': 'translation-ja.json',
    'components.formItemComponents.PhoneNumber': 'translation-ja.json',
    'components.formItemComponents.SimpleSelect': 'translation-ja.json',
    'components.formItemComponents.Structure': 'translation-ja.json',
    'components.formItemComponents.VerticalSelect': 'translation-ja.json',
    'components.formItemComponents.YearSelect': 'translation-ja.json',
    'components.katalComponents.CountrySelect': 'translation-ja.json',
    'components.katalComponents.DocumentTable': 'translation-ja.json',
    'components.katalComponents.DocumentUploader': 'translation-ja.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-ja.json',
    'components.modalComponents.EditDocModal': 'translation-ja.json',
    'libs.downloadToCsv': 'translation-ja.json',
    shared: 'translation-ja.json',
    'types.client.enums.ProductType': 'translation-ja.json',
    'types.document.enums.DocumentTag': 'translation-ja.json',
    'types.finance.enums.ChargeCode': 'translation-ja.json',
    'types.finance.enums.ReviewStatus': 'translation-ja.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-ja.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-ja.json',
    'types.notification.enum.NotificationOptions': 'translation-ja.json',
    'types.party.enums.BrokerRejectionReason': 'translation-ja.json',
    'types.party.enums.Structure': 'translation-ja.json',
    'types.shared.enums.LoadType': 'translation-ja.json',
    'types.shared.enums.SkillLevel': 'translation-ja.json',
    'types.shared.enums.SubscriptionPlan': 'translation-ja.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-ja.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-ja.json',
    'types.ui.enums.WorkOrderTab': 'translation-ja.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-ja.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-ja.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-ja.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-ja.json',
    'types.workOrder.enums.WorkOrderType': 'translation-ja.json',
  },
  nl: {
    'components.AccountingPointOfContacts': 'translation-nl.json',
    'components.ActivationInfo': 'translation-nl.json',
    'components.AppHeader': 'translation-nl.json',
    'components.AppRecord': 'translation-nl.json',
    'components.AppSiderMenu': 'translation-nl.json',
    'components.AppTable': 'translation-nl.json',
    'components.AssignWorkOrderTaskModal': 'translation-nl.json',
    'components.AssignmentTeam': 'translation-nl.json',
    'components.AssignmentWorkspace': 'translation-nl.json',
    'components.CancelShipmentModal': 'translation-nl.json',
    'components.CommodityGroup': 'translation-nl.json',
    'components.CreateWorkOrderModal': 'translation-nl.json',
    'components.CustomizeTableDrawer': 'translation-nl.json',
    'components.DocumentsPage': 'translation-nl.json',
    'components.Emails': 'translation-nl.json',
    'components.EuIorActivation': 'translation-nl.json',
    'components.ImporterEntityActivationSummary': 'translation-nl.json',
    'components.InltCharges': 'translation-nl.json',
    'components.Invoice': 'translation-nl.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-nl.json',
    'components.Milestones': 'translation-nl.json',
    'components.MilestonesTab': 'translation-nl.json',
    'components.NewBundleAvailable': 'translation-nl.json',
    'components.Party': 'translation-nl.json',
    'components.PasswordHelper': 'translation-nl.json',
    'components.PayableInvoiceSummaryForm': 'translation-nl.json',
    'components.PersonalInfo': 'translation-nl.json',
    'components.ProfileForm': 'translation-nl.json',
    'components.RecordSelect': 'translation-nl.json',
    'components.RecordTable': 'translation-nl.json',
    'components.RejectIorActivationModal': 'translation-nl.json',
    'components.Shipment': 'translation-nl.json',
    'components.ShipmentInfo': 'translation-nl.json',
    'components.ShipperSelect': 'translation-nl.json',
    'components.UnplannedCharges': 'translation-nl.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-nl.json',
    'components.User': 'translation-nl.json',
    'components.WorkOrder': 'translation-nl.json',
    'components.WorkOrderTabs': 'translation-nl.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-nl.json',
    'components.WorkOrderTaskInfoCard': 'translation-nl.json',
    'components.WorkOrderTaskSteps': 'translation-nl.json',
    'components.WorkOrderTasks': 'translation-nl.json',
    'components.buttonComponents.MinusButton': 'translation-nl.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-nl.json',
    'components.formComponents.EuIorInfoCard': 'translation-nl.json',
    'components.formComponents.LocationForm': 'translation-nl.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-nl.json',
    'components.formComponents.PartyFormBase': 'translation-nl.json',
    'components.formComponents.PointOfContact': 'translation-nl.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-nl.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-nl.json',
    'components.formComponents.UsCbpInfoCard': 'translation-nl.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-nl.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-nl.json',
    'components.formItemComponents.DUNSNumber': 'translation-nl.json',
    'components.formItemComponents.Email': 'translation-nl.json',
    'components.formItemComponents.IORNumber': 'translation-nl.json',
    'components.formItemComponents.PhoneNumber': 'translation-nl.json',
    'components.formItemComponents.SimpleSelect': 'translation-nl.json',
    'components.formItemComponents.Structure': 'translation-nl.json',
    'components.formItemComponents.VerticalSelect': 'translation-nl.json',
    'components.formItemComponents.YearSelect': 'translation-nl.json',
    'components.katalComponents.CountrySelect': 'translation-nl.json',
    'components.katalComponents.DocumentTable': 'translation-nl.json',
    'components.katalComponents.DocumentUploader': 'translation-nl.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-nl.json',
    'components.modalComponents.EditDocModal': 'translation-nl.json',
    'libs.downloadToCsv': 'translation-nl.json',
    shared: 'translation-nl.json',
    'types.client.enums.ProductType': 'translation-nl.json',
    'types.document.enums.DocumentTag': 'translation-nl.json',
    'types.finance.enums.ChargeCode': 'translation-nl.json',
    'types.finance.enums.ReviewStatus': 'translation-nl.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-nl.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-nl.json',
    'types.notification.enum.NotificationOptions': 'translation-nl.json',
    'types.party.enums.BrokerRejectionReason': 'translation-nl.json',
    'types.party.enums.Structure': 'translation-nl.json',
    'types.shared.enums.LoadType': 'translation-nl.json',
    'types.shared.enums.SkillLevel': 'translation-nl.json',
    'types.shared.enums.SubscriptionPlan': 'translation-nl.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-nl.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-nl.json',
    'types.ui.enums.WorkOrderTab': 'translation-nl.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-nl.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-nl.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-nl.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderType': 'translation-nl.json',
  },
  'nl-NL': {
    'components.AccountingPointOfContacts': 'translation-nl.json',
    'components.ActivationInfo': 'translation-nl.json',
    'components.AppHeader': 'translation-nl.json',
    'components.AppRecord': 'translation-nl.json',
    'components.AppSiderMenu': 'translation-nl.json',
    'components.AppTable': 'translation-nl.json',
    'components.AssignWorkOrderTaskModal': 'translation-nl.json',
    'components.AssignmentTeam': 'translation-nl.json',
    'components.AssignmentWorkspace': 'translation-nl.json',
    'components.CancelShipmentModal': 'translation-nl.json',
    'components.CommodityGroup': 'translation-nl.json',
    'components.CreateWorkOrderModal': 'translation-nl.json',
    'components.CustomizeTableDrawer': 'translation-nl.json',
    'components.DocumentsPage': 'translation-nl.json',
    'components.Emails': 'translation-nl.json',
    'components.EuIorActivation': 'translation-nl.json',
    'components.ImporterEntityActivationSummary': 'translation-nl.json',
    'components.InltCharges': 'translation-nl.json',
    'components.Invoice': 'translation-nl.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-nl.json',
    'components.Milestones': 'translation-nl.json',
    'components.MilestonesTab': 'translation-nl.json',
    'components.NewBundleAvailable': 'translation-nl.json',
    'components.Party': 'translation-nl.json',
    'components.PasswordHelper': 'translation-nl.json',
    'components.PayableInvoiceSummaryForm': 'translation-nl.json',
    'components.PersonalInfo': 'translation-nl.json',
    'components.ProfileForm': 'translation-nl.json',
    'components.RecordSelect': 'translation-nl.json',
    'components.RecordTable': 'translation-nl.json',
    'components.RejectIorActivationModal': 'translation-nl.json',
    'components.Shipment': 'translation-nl.json',
    'components.ShipmentInfo': 'translation-nl.json',
    'components.ShipperSelect': 'translation-nl.json',
    'components.UnplannedCharges': 'translation-nl.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-nl.json',
    'components.User': 'translation-nl.json',
    'components.WorkOrder': 'translation-nl.json',
    'components.WorkOrderTabs': 'translation-nl.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-nl.json',
    'components.WorkOrderTaskInfoCard': 'translation-nl.json',
    'components.WorkOrderTaskSteps': 'translation-nl.json',
    'components.WorkOrderTasks': 'translation-nl.json',
    'components.buttonComponents.MinusButton': 'translation-nl.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-nl.json',
    'components.formComponents.EuIorInfoCard': 'translation-nl.json',
    'components.formComponents.LocationForm': 'translation-nl.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-nl.json',
    'components.formComponents.PartyFormBase': 'translation-nl.json',
    'components.formComponents.PointOfContact': 'translation-nl.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-nl.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-nl.json',
    'components.formComponents.UsCbpInfoCard': 'translation-nl.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-nl.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-nl.json',
    'components.formItemComponents.DUNSNumber': 'translation-nl.json',
    'components.formItemComponents.Email': 'translation-nl.json',
    'components.formItemComponents.IORNumber': 'translation-nl.json',
    'components.formItemComponents.PhoneNumber': 'translation-nl.json',
    'components.formItemComponents.SimpleSelect': 'translation-nl.json',
    'components.formItemComponents.Structure': 'translation-nl.json',
    'components.formItemComponents.VerticalSelect': 'translation-nl.json',
    'components.formItemComponents.YearSelect': 'translation-nl.json',
    'components.katalComponents.CountrySelect': 'translation-nl.json',
    'components.katalComponents.DocumentTable': 'translation-nl.json',
    'components.katalComponents.DocumentUploader': 'translation-nl.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-nl.json',
    'components.modalComponents.EditDocModal': 'translation-nl.json',
    'libs.downloadToCsv': 'translation-nl.json',
    shared: 'translation-nl.json',
    'types.client.enums.ProductType': 'translation-nl.json',
    'types.document.enums.DocumentTag': 'translation-nl.json',
    'types.finance.enums.ChargeCode': 'translation-nl.json',
    'types.finance.enums.ReviewStatus': 'translation-nl.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-nl.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-nl.json',
    'types.notification.enum.NotificationOptions': 'translation-nl.json',
    'types.party.enums.BrokerRejectionReason': 'translation-nl.json',
    'types.party.enums.Structure': 'translation-nl.json',
    'types.shared.enums.LoadType': 'translation-nl.json',
    'types.shared.enums.SkillLevel': 'translation-nl.json',
    'types.shared.enums.SubscriptionPlan': 'translation-nl.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-nl.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-nl.json',
    'types.ui.enums.WorkOrderTab': 'translation-nl.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-nl.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-nl.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-nl.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-nl.json',
    'types.workOrder.enums.WorkOrderType': 'translation-nl.json',
  },
  pt: {
    'components.AccountingPointOfContacts': 'translation-pt.json',
    'components.ActivationInfo': 'translation-pt.json',
    'components.AppHeader': 'translation-pt.json',
    'components.AppRecord': 'translation-pt.json',
    'components.AppSiderMenu': 'translation-pt.json',
    'components.AppTable': 'translation-pt.json',
    'components.AssignWorkOrderTaskModal': 'translation-pt.json',
    'components.AssignmentTeam': 'translation-pt.json',
    'components.AssignmentWorkspace': 'translation-pt.json',
    'components.CancelShipmentModal': 'translation-pt.json',
    'components.CommodityGroup': 'translation-pt.json',
    'components.CreateWorkOrderModal': 'translation-pt.json',
    'components.CustomizeTableDrawer': 'translation-pt.json',
    'components.DocumentsPage': 'translation-pt.json',
    'components.Emails': 'translation-pt.json',
    'components.EuIorActivation': 'translation-pt.json',
    'components.ImporterEntityActivationSummary': 'translation-pt.json',
    'components.InltCharges': 'translation-pt.json',
    'components.Invoice': 'translation-pt.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-pt.json',
    'components.Milestones': 'translation-pt.json',
    'components.MilestonesTab': 'translation-pt.json',
    'components.NewBundleAvailable': 'translation-pt.json',
    'components.Party': 'translation-pt.json',
    'components.PasswordHelper': 'translation-pt.json',
    'components.PayableInvoiceSummaryForm': 'translation-pt.json',
    'components.PersonalInfo': 'translation-pt.json',
    'components.ProfileForm': 'translation-pt.json',
    'components.RecordSelect': 'translation-pt.json',
    'components.RecordTable': 'translation-pt.json',
    'components.RejectIorActivationModal': 'translation-pt.json',
    'components.Shipment': 'translation-pt.json',
    'components.ShipmentInfo': 'translation-pt.json',
    'components.ShipperSelect': 'translation-pt.json',
    'components.UnplannedCharges': 'translation-pt.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-pt.json',
    'components.User': 'translation-pt.json',
    'components.WorkOrder': 'translation-pt.json',
    'components.WorkOrderTabs': 'translation-pt.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-pt.json',
    'components.WorkOrderTaskInfoCard': 'translation-pt.json',
    'components.WorkOrderTaskSteps': 'translation-pt.json',
    'components.WorkOrderTasks': 'translation-pt.json',
    'components.buttonComponents.MinusButton': 'translation-pt.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-pt.json',
    'components.formComponents.EuIorInfoCard': 'translation-pt.json',
    'components.formComponents.LocationForm': 'translation-pt.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-pt.json',
    'components.formComponents.PartyFormBase': 'translation-pt.json',
    'components.formComponents.PointOfContact': 'translation-pt.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-pt.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-pt.json',
    'components.formComponents.UsCbpInfoCard': 'translation-pt.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-pt.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-pt.json',
    'components.formItemComponents.DUNSNumber': 'translation-pt.json',
    'components.formItemComponents.Email': 'translation-pt.json',
    'components.formItemComponents.IORNumber': 'translation-pt.json',
    'components.formItemComponents.PhoneNumber': 'translation-pt.json',
    'components.formItemComponents.SimpleSelect': 'translation-pt.json',
    'components.formItemComponents.Structure': 'translation-pt.json',
    'components.formItemComponents.VerticalSelect': 'translation-pt.json',
    'components.formItemComponents.YearSelect': 'translation-pt.json',
    'components.katalComponents.CountrySelect': 'translation-pt.json',
    'components.katalComponents.DocumentTable': 'translation-pt.json',
    'components.katalComponents.DocumentUploader': 'translation-pt.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-pt.json',
    'components.modalComponents.EditDocModal': 'translation-pt.json',
    'libs.downloadToCsv': 'translation-pt.json',
    shared: 'translation-pt.json',
    'types.client.enums.ProductType': 'translation-pt.json',
    'types.document.enums.DocumentTag': 'translation-pt.json',
    'types.finance.enums.ChargeCode': 'translation-pt.json',
    'types.finance.enums.ReviewStatus': 'translation-pt.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-pt.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-pt.json',
    'types.notification.enum.NotificationOptions': 'translation-pt.json',
    'types.party.enums.BrokerRejectionReason': 'translation-pt.json',
    'types.party.enums.Structure': 'translation-pt.json',
    'types.shared.enums.LoadType': 'translation-pt.json',
    'types.shared.enums.SkillLevel': 'translation-pt.json',
    'types.shared.enums.SubscriptionPlan': 'translation-pt.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-pt.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-pt.json',
    'types.ui.enums.WorkOrderTab': 'translation-pt.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-pt.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-pt.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-pt.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderType': 'translation-pt.json',
  },
  'pt-BR': {
    'components.AccountingPointOfContacts': 'translation-pt.json',
    'components.ActivationInfo': 'translation-pt.json',
    'components.AppHeader': 'translation-pt.json',
    'components.AppRecord': 'translation-pt.json',
    'components.AppSiderMenu': 'translation-pt.json',
    'components.AppTable': 'translation-pt.json',
    'components.AssignWorkOrderTaskModal': 'translation-pt.json',
    'components.AssignmentTeam': 'translation-pt.json',
    'components.AssignmentWorkspace': 'translation-pt.json',
    'components.CancelShipmentModal': 'translation-pt.json',
    'components.CommodityGroup': 'translation-pt.json',
    'components.CreateWorkOrderModal': 'translation-pt.json',
    'components.CustomizeTableDrawer': 'translation-pt.json',
    'components.DocumentsPage': 'translation-pt.json',
    'components.Emails': 'translation-pt.json',
    'components.EuIorActivation': 'translation-pt.json',
    'components.ImporterEntityActivationSummary': 'translation-pt.json',
    'components.InltCharges': 'translation-pt.json',
    'components.Invoice': 'translation-pt.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-pt.json',
    'components.Milestones': 'translation-pt.json',
    'components.MilestonesTab': 'translation-pt.json',
    'components.NewBundleAvailable': 'translation-pt.json',
    'components.Party': 'translation-pt.json',
    'components.PasswordHelper': 'translation-pt.json',
    'components.PayableInvoiceSummaryForm': 'translation-pt.json',
    'components.PersonalInfo': 'translation-pt.json',
    'components.ProfileForm': 'translation-pt.json',
    'components.RecordSelect': 'translation-pt.json',
    'components.RecordTable': 'translation-pt.json',
    'components.RejectIorActivationModal': 'translation-pt.json',
    'components.Shipment': 'translation-pt.json',
    'components.ShipmentInfo': 'translation-pt.json',
    'components.ShipperSelect': 'translation-pt.json',
    'components.UnplannedCharges': 'translation-pt.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-pt.json',
    'components.User': 'translation-pt.json',
    'components.WorkOrder': 'translation-pt.json',
    'components.WorkOrderTabs': 'translation-pt.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-pt.json',
    'components.WorkOrderTaskInfoCard': 'translation-pt.json',
    'components.WorkOrderTaskSteps': 'translation-pt.json',
    'components.WorkOrderTasks': 'translation-pt.json',
    'components.buttonComponents.MinusButton': 'translation-pt.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-pt.json',
    'components.formComponents.EuIorInfoCard': 'translation-pt.json',
    'components.formComponents.LocationForm': 'translation-pt.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-pt.json',
    'components.formComponents.PartyFormBase': 'translation-pt.json',
    'components.formComponents.PointOfContact': 'translation-pt.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-pt.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-pt.json',
    'components.formComponents.UsCbpInfoCard': 'translation-pt.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-pt.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-pt.json',
    'components.formItemComponents.DUNSNumber': 'translation-pt.json',
    'components.formItemComponents.Email': 'translation-pt.json',
    'components.formItemComponents.IORNumber': 'translation-pt.json',
    'components.formItemComponents.PhoneNumber': 'translation-pt.json',
    'components.formItemComponents.SimpleSelect': 'translation-pt.json',
    'components.formItemComponents.Structure': 'translation-pt.json',
    'components.formItemComponents.VerticalSelect': 'translation-pt.json',
    'components.formItemComponents.YearSelect': 'translation-pt.json',
    'components.katalComponents.CountrySelect': 'translation-pt.json',
    'components.katalComponents.DocumentTable': 'translation-pt.json',
    'components.katalComponents.DocumentUploader': 'translation-pt.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-pt.json',
    'components.modalComponents.EditDocModal': 'translation-pt.json',
    'libs.downloadToCsv': 'translation-pt.json',
    shared: 'translation-pt.json',
    'types.client.enums.ProductType': 'translation-pt.json',
    'types.document.enums.DocumentTag': 'translation-pt.json',
    'types.finance.enums.ChargeCode': 'translation-pt.json',
    'types.finance.enums.ReviewStatus': 'translation-pt.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-pt.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-pt.json',
    'types.notification.enum.NotificationOptions': 'translation-pt.json',
    'types.party.enums.BrokerRejectionReason': 'translation-pt.json',
    'types.party.enums.Structure': 'translation-pt.json',
    'types.shared.enums.LoadType': 'translation-pt.json',
    'types.shared.enums.SkillLevel': 'translation-pt.json',
    'types.shared.enums.SubscriptionPlan': 'translation-pt.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-pt.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-pt.json',
    'types.ui.enums.WorkOrderTab': 'translation-pt.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-pt.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-pt.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-pt.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-pt.json',
    'types.workOrder.enums.WorkOrderType': 'translation-pt.json',
  },
  und: {
    'components.AccountingPointOfContacts': 'translation.json',
    'components.ActivationInfo': 'translation.json',
    'components.AppHeader': 'translation.json',
    'components.AppRecord': 'translation.json',
    'components.AppSiderMenu': 'translation.json',
    'components.AppTable': 'translation.json',
    'components.AssignWorkOrderTaskModal': 'translation.json',
    'components.AssignmentTeam': 'translation.json',
    'components.AssignmentWorkspace': 'translation.json',
    'components.CancelShipmentModal': 'translation.json',
    'components.CancelWorkOrderModal': 'translation.json',
    'components.CommodityGroup': 'translation.json',
    'components.CreateWorkOrderModal': 'translation.json',
    'components.CustomizeTableDrawer': 'translation.json',
    'components.DocumentsPage': 'translation.json',
    'components.Emails': 'translation.json',
    'components.EuIorActivation': 'translation.json',
    'components.ImporterEntityActivationSummary': 'translation.json',
    'components.InltCharges': 'translation.json',
    'components.Invoice': 'translation.json',
    'components.MarkWorkOrderReadyOrHot': 'translation.json',
    'components.Milestones': 'translation.json',
    'components.MilestonesTab': 'translation.json',
    'components.NewBundleAvailable': 'translation.json',
    'components.Party': 'translation.json',
    'components.PasswordHelper': 'translation.json',
    'components.PayableInvoiceSummaryForm': 'translation.json',
    'components.PersonalInfo': 'translation.json',
    'components.ProfileForm': 'translation.json',
    'components.RecordSelect': 'translation.json',
    'components.RecordTable': 'translation.json',
    'components.RejectIorActivationModal': 'translation.json',
    'components.Shipment': 'translation.json',
    'components.ShipmentInfo': 'translation.json',
    'components.ShipperSelect': 'translation.json',
    'components.UnplannedCharges': 'translation.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation.json',
    'components.User': 'translation.json',
    'components.WorkOrder': 'translation.json',
    'components.WorkOrderTabs': 'translation.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation.json',
    'components.WorkOrderTaskInfoCard': 'translation.json',
    'components.WorkOrderTaskSteps': 'translation.json',
    'components.WorkOrderTasks': 'translation.json',
    'components.buttonComponents.MinusButton': 'translation.json',
    'components.displayComponents.DocumentDisSubmissions': 'translation.json',
    'components.formComponents.EuIorInfoCard': 'translation.json',
    'components.formComponents.LocationForm': 'translation.json',
    'components.formComponents.OptionalCompanyInfo': 'translation.json',
    'components.formComponents.PartyFormBase': 'translation.json',
    'components.formComponents.PointOfContact': 'translation.json',
    'components.formComponents.TemplateBasedSignFields': 'translation.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation.json',
    'components.formComponents.UsCbpInfoCard': 'translation.json',
    'components.formComponents.UsIorOfficerInfo': 'translation.json',
    'components.formItemComponents.CBPNumberReasonSelect': 'translation.json',
    'components.formItemComponents.DUNSNumber': 'translation.json',
    'components.formItemComponents.Email': 'translation.json',
    'components.formItemComponents.IORNumber': 'translation.json',
    'components.formItemComponents.PhoneNumber': 'translation.json',
    'components.formItemComponents.SimpleSelect': 'translation.json',
    'components.formItemComponents.Structure': 'translation.json',
    'components.formItemComponents.VerticalSelect': 'translation.json',
    'components.formItemComponents.YearSelect': 'translation.json',
    'components.katalComponents.CountrySelect': 'translation.json',
    'components.katalComponents.DocumentTable': 'translation.json',
    'components.katalComponents.DocumentTagModal': 'translation.json',
    'components.katalComponents.DocumentUploader': 'translation.json',
    'components.katalComponents.ProductConfirmationModal': 'translation.json',
    'components.modalComponents.EditDocModal': 'translation.json',
    'libs.downloadToCsv': 'translation.json',
    shared: 'translation.json',
    'types.client.enums.ProductType': 'translation.json',
    'types.document.enums.DocumentTag': 'translation.json',
    'types.finance.enums.ChargeCode': 'translation.json',
    'types.finance.enums.ReviewStatus': 'translation.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation.json',
    'types.notification.enum.NotificationOptions': 'translation.json',
    'types.party.enums.BrokerRejectionReason': 'translation.json',
    'types.party.enums.Structure': 'translation.json',
    'types.shared.enums.LoadType': 'translation.json',
    'types.shared.enums.RecordName': 'translation.json',
    'types.shared.enums.SkillLevel': 'translation.json',
    'types.shared.enums.SubscriptionPlan': 'translation.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation.json',
    'types.ui.enums.WorkOrderTab': 'translation.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation.json',
    'types.workOrder.enums.WorkOrderType': 'translation.json',
  },
  zh: {
    'components.AccountingPointOfContacts': 'translation-zh.json',
    'components.ActivationInfo': 'translation-zh.json',
    'components.AppHeader': 'translation-zh.json',
    'components.AppRecord': 'translation-zh.json',
    'components.AppSiderMenu': 'translation-zh.json',
    'components.AppTable': 'translation-zh.json',
    'components.AssignWorkOrderTaskModal': 'translation-zh.json',
    'components.AssignmentTeam': 'translation-zh.json',
    'components.AssignmentWorkspace': 'translation-zh.json',
    'components.CancelShipmentModal': 'translation-zh.json',
    'components.CommodityGroup': 'translation-zh.json',
    'components.CreateWorkOrderModal': 'translation-zh.json',
    'components.CustomizeTableDrawer': 'translation-zh.json',
    'components.DocumentsPage': 'translation-zh.json',
    'components.Emails': 'translation-zh.json',
    'components.EuIorActivation': 'translation-zh.json',
    'components.ImporterEntityActivationSummary': 'translation-zh.json',
    'components.InltCharges': 'translation-zh.json',
    'components.Invoice': 'translation-zh.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-zh.json',
    'components.Milestones': 'translation-zh.json',
    'components.MilestonesTab': 'translation-zh.json',
    'components.NewBundleAvailable': 'translation-zh.json',
    'components.Party': 'translation-zh.json',
    'components.PasswordHelper': 'translation-zh.json',
    'components.PayableInvoiceSummaryForm': 'translation-zh.json',
    'components.PersonalInfo': 'translation-zh.json',
    'components.ProfileForm': 'translation-zh.json',
    'components.RecordSelect': 'translation-zh.json',
    'components.RecordTable': 'translation-zh.json',
    'components.RejectIorActivationModal': 'translation-zh.json',
    'components.Shipment': 'translation-zh.json',
    'components.ShipmentInfo': 'translation-zh.json',
    'components.ShipperSelect': 'translation-zh.json',
    'components.UnplannedCharges': 'translation-zh.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-zh.json',
    'components.User': 'translation-zh.json',
    'components.WorkOrder': 'translation-zh.json',
    'components.WorkOrderTabs': 'translation-zh.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-zh.json',
    'components.WorkOrderTaskInfoCard': 'translation-zh.json',
    'components.WorkOrderTaskSteps': 'translation-zh.json',
    'components.WorkOrderTasks': 'translation-zh.json',
    'components.buttonComponents.MinusButton': 'translation-zh.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-zh.json',
    'components.formComponents.EuIorInfoCard': 'translation-zh.json',
    'components.formComponents.LocationForm': 'translation-zh.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-zh.json',
    'components.formComponents.PartyFormBase': 'translation-zh.json',
    'components.formComponents.PointOfContact': 'translation-zh.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-zh.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-zh.json',
    'components.formComponents.UsCbpInfoCard': 'translation-zh.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-zh.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-zh.json',
    'components.formItemComponents.DUNSNumber': 'translation-zh.json',
    'components.formItemComponents.Email': 'translation-zh.json',
    'components.formItemComponents.IORNumber': 'translation-zh.json',
    'components.formItemComponents.PhoneNumber': 'translation-zh.json',
    'components.formItemComponents.SimpleSelect': 'translation-zh.json',
    'components.formItemComponents.Structure': 'translation-zh.json',
    'components.formItemComponents.VerticalSelect': 'translation-zh.json',
    'components.formItemComponents.YearSelect': 'translation-zh.json',
    'components.katalComponents.CountrySelect': 'translation-zh.json',
    'components.katalComponents.DocumentTable': 'translation-zh.json',
    'components.katalComponents.DocumentUploader': 'translation-zh.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-zh.json',
    'components.modalComponents.EditDocModal': 'translation-zh.json',
    'libs.downloadToCsv': 'translation-zh.json',
    shared: 'translation-zh.json',
    'types.client.enums.ProductType': 'translation-zh.json',
    'types.document.enums.DocumentTag': 'translation-zh.json',
    'types.finance.enums.ChargeCode': 'translation-zh.json',
    'types.finance.enums.ReviewStatus': 'translation-zh.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-zh.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-zh.json',
    'types.notification.enum.NotificationOptions': 'translation-zh.json',
    'types.party.enums.BrokerRejectionReason': 'translation-zh.json',
    'types.party.enums.Structure': 'translation-zh.json',
    'types.shared.enums.LoadType': 'translation-zh.json',
    'types.shared.enums.SkillLevel': 'translation-zh.json',
    'types.shared.enums.SubscriptionPlan': 'translation-zh.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-zh.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-zh.json',
    'types.ui.enums.WorkOrderTab': 'translation-zh.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-zh.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-zh.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-zh.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderType': 'translation-zh.json',
  },
  'zh-CN': {
    'components.AccountingPointOfContacts': 'translation-zh.json',
    'components.ActivationInfo': 'translation-zh.json',
    'components.AppHeader': 'translation-zh.json',
    'components.AppRecord': 'translation-zh.json',
    'components.AppSiderMenu': 'translation-zh.json',
    'components.AppTable': 'translation-zh.json',
    'components.AssignWorkOrderTaskModal': 'translation-zh.json',
    'components.AssignmentTeam': 'translation-zh.json',
    'components.AssignmentWorkspace': 'translation-zh.json',
    'components.CancelShipmentModal': 'translation-zh.json',
    'components.CommodityGroup': 'translation-zh.json',
    'components.CreateWorkOrderModal': 'translation-zh.json',
    'components.CustomizeTableDrawer': 'translation-zh.json',
    'components.DocumentsPage': 'translation-zh.json',
    'components.Emails': 'translation-zh.json',
    'components.EuIorActivation': 'translation-zh.json',
    'components.ImporterEntityActivationSummary': 'translation-zh.json',
    'components.InltCharges': 'translation-zh.json',
    'components.Invoice': 'translation-zh.json',
    'components.MarkWorkOrderReadyOrHot': 'translation-zh.json',
    'components.Milestones': 'translation-zh.json',
    'components.MilestonesTab': 'translation-zh.json',
    'components.NewBundleAvailable': 'translation-zh.json',
    'components.Party': 'translation-zh.json',
    'components.PasswordHelper': 'translation-zh.json',
    'components.PayableInvoiceSummaryForm': 'translation-zh.json',
    'components.PersonalInfo': 'translation-zh.json',
    'components.ProfileForm': 'translation-zh.json',
    'components.RecordSelect': 'translation-zh.json',
    'components.RecordTable': 'translation-zh.json',
    'components.RejectIorActivationModal': 'translation-zh.json',
    'components.Shipment': 'translation-zh.json',
    'components.ShipmentInfo': 'translation-zh.json',
    'components.ShipperSelect': 'translation-zh.json',
    'components.UnplannedCharges': 'translation-zh.json',
    'components.UsIorContinuousBondRequestInfoFields': 'translation-zh.json',
    'components.User': 'translation-zh.json',
    'components.WorkOrder': 'translation-zh.json',
    'components.WorkOrderTabs': 'translation-zh.json',
    'components.WorkOrderTaskAutoExecutionDetails': 'translation-zh.json',
    'components.WorkOrderTaskInfoCard': 'translation-zh.json',
    'components.WorkOrderTaskSteps': 'translation-zh.json',
    'components.WorkOrderTasks': 'translation-zh.json',
    'components.buttonComponents.MinusButton': 'translation-zh.json',
    'components.displayComponents.DocumentDisSubmissions':
      'translation-zh.json',
    'components.formComponents.EuIorInfoCard': 'translation-zh.json',
    'components.formComponents.LocationForm': 'translation-zh.json',
    'components.formComponents.OptionalCompanyInfo': 'translation-zh.json',
    'components.formComponents.PartyFormBase': 'translation-zh.json',
    'components.formComponents.PointOfContact': 'translation-zh.json',
    'components.formComponents.TemplateBasedSignFields': 'translation-zh.json',
    'components.formComponents.TemplateBasedSignSubmissionContent':
      'translation-zh.json',
    'components.formComponents.UsCbpInfoCard': 'translation-zh.json',
    'components.formComponents.UsIorOfficerInfo': 'translation-zh.json',
    'components.formItemComponents.CBPNumberReasonSelect':
      'translation-zh.json',
    'components.formItemComponents.DUNSNumber': 'translation-zh.json',
    'components.formItemComponents.Email': 'translation-zh.json',
    'components.formItemComponents.IORNumber': 'translation-zh.json',
    'components.formItemComponents.PhoneNumber': 'translation-zh.json',
    'components.formItemComponents.SimpleSelect': 'translation-zh.json',
    'components.formItemComponents.Structure': 'translation-zh.json',
    'components.formItemComponents.VerticalSelect': 'translation-zh.json',
    'components.formItemComponents.YearSelect': 'translation-zh.json',
    'components.katalComponents.CountrySelect': 'translation-zh.json',
    'components.katalComponents.DocumentTable': 'translation-zh.json',
    'components.katalComponents.DocumentUploader': 'translation-zh.json',
    'components.katalComponents.ProductConfirmationModal':
      'translation-zh.json',
    'components.modalComponents.EditDocModal': 'translation-zh.json',
    'libs.downloadToCsv': 'translation-zh.json',
    shared: 'translation-zh.json',
    'types.client.enums.ProductType': 'translation-zh.json',
    'types.document.enums.DocumentTag': 'translation-zh.json',
    'types.finance.enums.ChargeCode': 'translation-zh.json',
    'types.finance.enums.ReviewStatus': 'translation-zh.json',
    'types.modeOfTransport.enum.ModeOfTransport': 'translation-zh.json',
    'types.nameMap.enum.InvoiceNameMap': 'translation-zh.json',
    'types.notification.enum.NotificationOptions': 'translation-zh.json',
    'types.party.enums.BrokerRejectionReason': 'translation-zh.json',
    'types.party.enums.Structure': 'translation-zh.json',
    'types.shared.enums.LoadType': 'translation-zh.json',
    'types.shared.enums.SkillLevel': 'translation-zh.json',
    'types.shared.enums.SubscriptionPlan': 'translation-zh.json',
    'types.ui.enums.AssignmentWorkspaceTab': 'translation-zh.json',
    'types.ui.enums.AssignmentWorkspaceTaskTab': 'translation-zh.json',
    'types.ui.enums.WorkOrderTab': 'translation-zh.json',
    'types.workOrder.enums.TaskExecutionStatus': 'translation-zh.json',
    'types.workOrder.enums.UsCbpReleaseStatus': 'translation-zh.json',
    'types.workOrder.enums.UsCbpSummaryStatus': 'translation-zh.json',
    'types.workOrder.enums.UsIsfCbpStatus': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderInquiryReasonCode': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderMilestoneName': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderStatus': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderTaskStatus': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderTaskType': 'translation-zh.json',
    'types.workOrder.enums.WorkOrderType': 'translation-zh.json',
  },
};
const arbManifest = {
  endpointMap: endpointMap,
};

export { arbManifest as default, endpointMap };
