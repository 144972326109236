import { Form, Input, Tabs, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { get, uniqBy } from 'lodash';
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  MutableRefObject,
} from 'react';
import type {
  AdditionalHts,
  UsConsumptionEntryCensusWarning,
} from '@xbcb/api-gateway-client';
import { InlineTooltip } from '@xbcb/feedback-components';
import { isRealHts as realHTS } from '@xbcb/entry-utils/dist/lib/htsUtil';
import {
  ANNumber,
  Checkbox,
  CountrySelect,
  FormItem,
  HiddenField,
  INLTSwitch,
  SimpleSelect as Select,
} from '@xbcb/form-item-components';
import { formatHTS } from '@xbcb/hts-client-utils';
import { timeout } from '@xbcb/js-utils';
import {
  usConsumptionEntrySupplierFields,
  usPostSummaryCorrectionSupplierFields,
} from '@xbcb/party-queries';
import { AccountType, AnyObject, RecordType } from '@xbcb/shared-types';
import { CssSize, DataCyTabPrefix, NamePath } from '@xbcb/ui-types';
import {
  getCodes,
  getExclusionNumber,
  shouldUpdate,
  show,
} from '@xbcb/ui-utils';
import CanadianProvinceSelect from 'components/CanadianProvinceSelect';
import HelpLink from 'components/HelpLink';
import HTSNumber from 'components/HTSNumber';
import LineItemCase from 'components/LineItemCase';
import CensusWarningsTab from 'components/LineItemCensusWarningsTab';
import LineItemHTSSummary from 'components/LineItemHTSSummary';
import MoneyInput from 'components/MoneyInput';
import PgaForm from 'components/PgaForm';
import RecordSelect from 'components/RecordSelect';
import SPISelect from 'components/SPISelect';
import Tariff from 'components/Tariff';
import { clearFields } from 'libs/clearFields';
import dutyComputation from 'libs/dutyDisplay';
import {
  UsPrincipalAdCvdCaseType,
  filterForActiveAdCvdCases,
} from 'libs/filterAdCvdCases';
import { formatHTSNumber } from 'libs/format';
import {
  getArrivalDate,
  isSection232AluminumSmeltAndCastApplicableForAnyTariff,
  isSection232SteelMeltAndPourApplicableForAnyTariff,
} from 'libs/htsHelpers';
import {
  useHtsQuery,
  useWorkOrderTaskConfiguration,
  usePrevious,
} from 'libs/hooks';
import mapEmbeddedSupplierFields from 'libs/mapEmbeddedSupplierFields';
import {
  getAddressRequiredFields,
  getPostalCodeRequiredField,
  getMidRequiredField,
  getNameRequiredField,
} from 'libs/requiredEmbeddedFieldHelpers';
import {
  usCbpEntryFeeCodeToTypeMap,
  usCbpEntryTaxCodeToTypeMap,
} from 'libs/taxesFeesBimaps';
import tooltips from 'libs/tooltips';
import {
  StyledFlexDiv,
  StyledFormItem,
  StyledHelpLinkDiv,
  StyledInlineDiv,
  StyledLineFeeParagraph,
  StyledPgaFormTab,
  StyledTabs,
  StyledTariffInfoTabPane,
} from './styles';
import { HtsObject } from 'types';
import AluminumSmeltAndCastCountryDetail from 'components/AluminumSmeltAndCastCountryDetail';
import PendingUpdatesHighlight from 'components/PendingUpdatesHighlight';
import SteelMeltAndPourCountryDetail from 'components/SteelMeltAndPourCountryDetail';
import { HTS_INDICES } from 'libs/htsIndices';

const checkStartsWithArray =
  (substrings: string[]) =>
  (s?: string): boolean => {
    if (!s) return false;
    return substrings.some((substring) => s.startsWith(substring));
  };

export const isSection232 = checkStartsWithArray(['SECTION_232', 'section232']);
export const isSection301 = checkStartsWithArray(['SECTION_301', 'section301']);

const getAdditionalGrouped = (htsObj: HtsObject, originCountryCode: string) => {
  const additional = (get(htsObj, 'additional', []) ?? []).filter(
    (a: AdditionalHts) =>
      (a.include && a.include.some((i: string) => i === originCountryCode)) ||
      (a.exclude && !a.exclude.some((i: string) => i === originCountryCode)),
  );
  return uniqBy(additional, 'type');
};

interface LineItemProps {
  form: FormInstance;
  keyRef?: MutableRefObject<string>;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  htsRequired?: boolean;
  accountType?: AccountType;
  consumptionEntry?: any;
  isFetching?: boolean;
  generateEntryNumber?: any;
  lineActions?: any;
  importer?: any;
  updateUI?: any;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  $noMarginBottom?: boolean;
  isPsc?: boolean;
  recordType?: RecordType;
}

const { TabPane } = Tabs;

// TODO: we should split this component up for easier management
const LineItem: React.FC<LineItemProps> = ({
  fullNamePath,
  accountType,
  consumptionEntry,
  isFetching,
  generateEntryNumber,
  form,
  keyRef,
  disabled,
  readOnly,
  required,
  lineActions,
  // htsRequired,
  importer,
  updateUI,
  localNamePath,
  $noMarginBottom,
  isPsc,
  recordType,
}) => {
  const { readWriteControlsConfig } = useWorkOrderTaskConfiguration();
  const codes = getCodes();
  const { CBP } = codes;
  const { CATAIR } = CBP;
  const licenseCodes = CATAIR.license;
  const lineFees = CATAIR.fee['62'];
  const taxClass = CATAIR.taxClass;
  const agencyCodes = CATAIR.PGA.agency;
  const pgaFlags = CATAIR.PGA.flag;
  const pscLineReasonCodes = CATAIR.PSC.lineReason;
  const arrivalNamePath = ['arrival'];
  const inBondNamePath = [...arrivalNamePath, 'inBond'];
  const { htsDataLoading, queryEffectiveUsHtss, getEffectiveUsHtssData } =
    useHtsQuery();
  const [removedTariffs, setRemovedTariffs] = useState<string[]>([]);

  const tariffKey = useCallback(
    (i: number) => {
      return [...fullNamePath, 'tariffs', i];
    },
    [fullNamePath],
  );

  const htsKey = useCallback(
    (i: number) => {
      return [...tariffKey(i), 'htsNumber'];
    },
    [tariffKey],
  );
  const poNumberKey = [...localNamePath, 'poNumber'];

  const { getFieldValue, setFields } = form;

  const originCountryCodeKey = useMemo(() => {
    return [...fullNamePath, 'origin', 'countryCode'];
  }, [fullNamePath]);

  const countryOfOrigin = getFieldValue(originCountryCodeKey);
  const [lineCountryOfOrigin, setLineCountryOfOrigin] =
    useState<string>(countryOfOrigin);
  const prevLineCountryOfOrigin = usePrevious(lineCountryOfOrigin);

  const [modifyingAdditional, setModifyingAdditional] = useState(false);

  const estimatedImportDate = getArrivalDate(form);

  const primaryHtsNumber = getFieldValue(htsKey(0)) || '';
  const prevPrimaryHtsNumber = usePrevious(primaryHtsNumber);
  const secondaryHtsNumber = getFieldValue(htsKey(1)) || '';
  const prevSecondaryHtsNumber = usePrevious(secondaryHtsNumber);
  const tertiaryHtsNumber = getFieldValue(htsKey(2)) || '';
  const prevTertiaryHtsNumber = usePrevious(tertiaryHtsNumber);
  const quaternaryHtsNumber = getFieldValue(htsKey(3)) || '';
  const prevQuaternaryHtsNumber = usePrevious(quaternaryHtsNumber);
  const arrivalDate = getArrivalDate(form);

  const effectiveUsHtssData = getEffectiveUsHtssData(arrivalDate);
  useEffect(() => {
    const fetchHts = async () => {
      // API will only be hit for complete/valid HTS numbers
      // that have not yet been fetched
      void queryEffectiveUsHtss(
        [
          primaryHtsNumber,
          secondaryHtsNumber,
          tertiaryHtsNumber,
          quaternaryHtsNumber,
        ],
        arrivalDate,
      );
    };
    if (
      primaryHtsNumber ||
      secondaryHtsNumber ||
      tertiaryHtsNumber ||
      quaternaryHtsNumber
    ) {
      void fetchHts();
    }
  }, [
    primaryHtsNumber,
    secondaryHtsNumber,
    tertiaryHtsNumber,
    quaternaryHtsNumber,
    arrivalDate,
    queryEffectiveUsHtss,
  ]);

  const censusWarnings: UsConsumptionEntryCensusWarning[] =
    getFieldValue([...fullNamePath, 'censusWarnings']) ?? [];
  // Warnings that have been resolved without an override code
  // are not displayed in the Census Warnings tab
  const hasDisplayableCensusWarnings = censusWarnings.some(
    (warning) =>
      warning.warningStatus === 'ACTIVE' || Boolean(warning.overrideCode),
  );

  const makeHtsValues = useCallback(() => {
    const primaryHtsNumber = getFieldValue(htsKey(0)) || '';
    const primaryHTSObj: HtsObject | undefined =
      effectiveUsHtssData[formatHTS(primaryHtsNumber || '')];

    const secondaryHtsNumber =
      get(primaryHTSObj, 'additionalHTS') && getFieldValue(htsKey(1));
    const secondaryHTSObj: HtsObject | undefined =
      effectiveUsHtssData[formatHTS(secondaryHtsNumber || '')];

    const tertiaryHtsNumber =
      get(secondaryHTSObj, 'additionalHTS') && getFieldValue(htsKey(2));

    const tertiaryHTSObj: HtsObject | undefined =
      effectiveUsHtssData[formatHTS(tertiaryHtsNumber || '')];

    const quaternaryHtsNumber =
      get(secondaryHTSObj, 'additionalHTS') && getFieldValue(htsKey(3));

    const quaternaryHTSObj: HtsObject | undefined =
      effectiveUsHtssData[formatHTS(quaternaryHtsNumber || '')];

    const originCountryCode = getFieldValue(originCountryCodeKey);
    const lineHtsNumber =
      quaternaryHtsNumber ||
      tertiaryHtsNumber ||
      secondaryHtsNumber ||
      primaryHtsNumber;
    const lineHTSObj =
      tertiaryHTSObj || secondaryHTSObj || primaryHTSObj || quaternaryHtsNumber;

    const licenseCode =
      get(quaternaryHTSObj, 'licenseCode') ||
      get(tertiaryHTSObj, 'licenseCode') ||
      get(secondaryHTSObj, 'licenseCode') ||
      get(primaryHTSObj, 'licenseCode');

    const htsNumbers: { [key: number]: string } = {
      0: primaryHtsNumber,
      1: secondaryHtsNumber,
      2: tertiaryHtsNumber,
      3: quaternaryHtsNumber,
    };

    const htsObjects: (HtsObject | undefined)[] = [
      primaryHTSObj,
      secondaryHTSObj,
      tertiaryHTSObj,
      quaternaryHTSObj,
    ];

    return {
      tertiaryHTSObj,
      originCountryCode,
      lineHtsNumber,
      htsObjects,
      htsNumbers,
      licenseCode,
      lineHTSObj,
      secondaryHTSObj,
      forthHTSObj: quaternaryHTSObj,
    };
  }, [effectiveUsHtssData, getFieldValue, htsKey, originCountryCodeKey]);

  const htsValues = makeHtsValues();
  const prevHtsValues: AnyObject | undefined = usePrevious(htsValues);

  const allPrincipalAdCvdCases = get(
    htsValues.lineHTSObj,
    'principalAdCvdCases',
    [],
  );

  const adCases = filterForActiveAdCvdCases(allPrincipalAdCvdCases, {
    caseType: UsPrincipalAdCvdCaseType.AD,
    countryCode: countryOfOrigin,
  });
  const cvCases = filterForActiveAdCvdCases(allPrincipalAdCvdCases, {
    caseType: UsPrincipalAdCvdCaseType.CV,
    countryCode: countryOfOrigin,
  });

  const adHts = adCases.length > 0;
  const cvHts = cvCases.length > 0;
  const [adTabManuallyAdded, setAdTabManuallyAdded] = useState(false);
  const [cvTabManuallyAdded, setCvTabManuallyAdded] = useState(false);

  const adTabVisible =
    adHts || !!getFieldValue(fullNamePath)?.adCase || adTabManuallyAdded;
  const cvTabVisible =
    cvHts || !!getFieldValue(fullNamePath)?.cvCase || cvTabManuallyAdded;

  // TODO this is hacky, fix it when there's time to. We are passing in consumptionEntry, isPSC, and doing this.
  const isWorkOrderType = fullNamePath.includes('invoices');

  const manufacturerBaseFullNamePath = [...fullNamePath, 'manufacturer'];
  const manufacturerKey = isWorkOrderType
    ? [...manufacturerBaseFullNamePath, 'supplier']
    : manufacturerBaseFullNamePath;
  const manufacturerRecordSelectField = isWorkOrderType
    ? [...localNamePath, 'manufacturer', 'supplier']
    : [...localNamePath, 'manufacturer'];

  const relatedManufacturer = [...localNamePath, 'manufacturer', 'related'];
  // Extract the product record id from Consumption Entry.
  const entryLineProductId =
    isWorkOrderType &&
    form.getFieldValue([
      // Need to go 2 levels above to get the product reference
      ...fullNamePath.slice(0, -2),
      'product',
      'id',
    ]);

  /*
   * See comment below for why its not needed
  const manufacturerPOCRequired = (
    requiredPOCs(form.getFieldsValue()) || []
  ).includes(manufacturerKey);
  */

  const removeAdditionalTariff = useCallback(
    async (t: number) => {
      setRemovedTariffs((previousRemovedTariffs) => [
        ...previousRemovedTariffs,
        formatHTS(htsValues.htsNumbers[t]),
      ]);
      const lastIndex = htsValues.tertiaryHTSObj ? 2 : 1;
      for (let i = t + 1; i <= lastIndex; i++) {
        const existingTariff = getFieldValue([...fullNamePath, 'tariffs', i]);
        if (!existingTariff) return;
        // TODO hack, need to move packaging levels to redux state
        let hasPackaging;
        if (existingTariff.pgas) {
          Object.keys(existingTariff.pgas).forEach((flag, index) => {
            const packaging = get(existingTariff, ['pgas', index, 'packaging']);
            if (packaging) {
              hasPackaging = true;
              updateUI({ packagingLevelsOverride: packaging.length });
            }
          });
        }
        const { htsNumber } = existingTariff;
        const setPayload = [
          {
            name: htsKey(i - 1),
            value: formatHTSNumber(htsNumber),
          },
          { name: htsKey(i), value: undefined },
        ];
        clearFields({
          form,
          fullNamePath: [...fullNamePath, 'tariffs', i],
        })();
        setFields(setPayload);
        await timeout(1);
        setFields([
          {
            name: [...fullNamePath, 'tariffs', i - 1],
            value: existingTariff,
          },
        ]);
        // do it again for conditional HTS fields
        await timeout(1);
        setFields([
          {
            name: [...fullNamePath, 'tariffs', i - 1],
            value: existingTariff,
          },
        ]);
        if (hasPackaging) {
          updateUI({ packagingLevelsOverride: undefined });
        }
      }
    },
    [
      form,
      fullNamePath,
      getFieldValue,
      htsKey,
      htsValues.tertiaryHTSObj,
      setFields,
      updateUI,
    ],
  );

  const addAdditionalTariff = useCallback(
    async (i: number, tariff: any) => {
      const existingTariff = getFieldValue([...fullNamePath, 'tariffs', i]);
      // TODO hack, need to move packaging levels to redux state
      let hasPackaging;
      if (existingTariff.pgas) {
        Object.keys(existingTariff.pgas).forEach((flag, index) => {
          const packaging = get(existingTariff, ['pgas', index, 'packaging']);
          if (packaging) {
            hasPackaging = true;
            updateUI({ packagingLevelsOverride: packaging.value });
          }
        });
      }
      const setPayload: { name: NamePath; value: any }[] = [
        {
          name: tariffKey(i),
          value: {
            htsNumber: formatHTSNumber(tariff.number),
          },
        },
      ];

      setFields(setPayload);
      await timeout(1);
      setFields([
        {
          name: htsKey(i + 1),
          value: existingTariff.htsNumber,
        },
      ]);
      await timeout(1);

      setFields([
        {
          name: [...fullNamePath, 'tariffs', i + 1],
          value: existingTariff,
        },
      ]);
      // do it again for conditional HTS fields
      await timeout(1);
      setFields([
        {
          name: [...fullNamePath, 'tariffs', i + 1],
          value: existingTariff,
        },
      ]);
      if (hasPackaging) {
        updateUI({ packagingLevelsOverride: undefined });
      }
    },
    [fullNamePath, getFieldValue, htsKey, setFields, tariffKey, updateUI],
  );

  const getAdditional232ExclusionNumber = useCallback(
    (additionalHts: AdditionalHts) => {
      const fullAdditional232Type = get(additionalHts, [0, 'type'], '');
      const additional232Type = fullAdditional232Type.startsWith('section232')
        ? fullAdditional232Type.substr(10)
        : fullAdditional232Type.substr(12);
      const additional232ExclusionFieldName = `${additional232Type.toLowerCase()}ExclusionNumber`;
      const fullAdditional232ExclusionField = [
        ...fullNamePath,
        additional232ExclusionFieldName,
      ];
      return getFieldValue(fullAdditional232ExclusionField);
    },
    [fullNamePath, getFieldValue],
  );

  const isAdditionalHtsApplicable = useCallback(
    (additionalHts: AdditionalHts) => {
      if (!additionalHts.type) {
        return false;
      }
      if (removedTariffs.includes(formatHTS(additionalHts.number))) {
        return false;
      }
      return (
        isSection301(additionalHts.type) &&
        // This section has been indefinitely suspended. Remove this check
        // if it goes live (https://ustr.gov/about-us/policy-offices/press-office/press-releases/2019/december/united-states-and-china-reach)
        !['section301List4B', 'SECTION_301_LIST_4B'].includes(
          additionalHts.type,
        )
      );
    },
    [getAdditional232ExclusionNumber],
  );

  const addAdditionalApplicableTariffs = useCallback(
    async (htsValues: AnyObject) => {
      if (
        recordType === RecordType.US_TYPE86_ENTRY || // T86 doesn't use additional HTS
        !htsValues.htsObjects[0] ||
        !htsValues.originCountryCode
      ) {
        return;
      }
      const additionalGrouped = getAdditionalGrouped(
        htsValues.htsObjects[0],
        htsValues.originCountryCode,
      );
      if (additionalGrouped.length !== 1) {
        return;
      }
      const a = additionalGrouped[0];
      if (isAdditionalHtsApplicable(a)) {
        await addAdditionalTariff(0, a);
      }
    },
    [addAdditionalTariff, isAdditionalHtsApplicable],
  );

  // When user changes inputted HTS numbers we should remove any additional tariffs that were added to a number that no longer exists.
  // Once stale additional tariffs are removed, any additional tariffs that apply to primary HTS are auto-added.
  useEffect(() => {
    const updateAdditionalTariffsOnHtsChange = async () => {
      if (!prevHtsValues) {
        return;
      }
      if (
        prevPrimaryHtsNumber === primaryHtsNumber &&
        prevSecondaryHtsNumber === secondaryHtsNumber &&
        prevTertiaryHtsNumber === tertiaryHtsNumber &&
        prevQuaternaryHtsNumber === quaternaryHtsNumber
      ) {
        return;
      }
      if (accountType !== AccountType.OPERATOR || readOnly) {
        return;
      }
      if (!modifyingAdditional) {
        setModifyingAdditional(true);
        const htsValues = makeHtsValues();
        const newHtsNumbers = HTS_INDICES.map((index) => {
          return formatHTS(htsValues.htsNumbers[index]);
        });
        for (const index of [1, 2, 3]) {
          if (
            prevHtsValues.htsNumbers[index] &&
            prevHtsValues.htsObjects[index] &&
            !newHtsNumbers.includes(formatHTS(prevHtsValues.htsNumbers[index]))
          ) {
            if (prevHtsValues.htsObjects[index].additional) {
              const additionalNumbers = prevHtsValues.htsObjects[
                index
              ].additional.map((additionalHts: AdditionalHts) => {
                return formatHTS(additionalHts.number);
              });
              if (
                additionalNumbers.includes(
                  formatHTS(htsValues.htsNumbers[index - 1]),
                )
              ) {
                await removeAdditionalTariff(index - 1);
              }
            }
          }
        }
        setModifyingAdditional(false);
        await addAdditionalApplicableTariffs(htsValues);
      }
    };
    void updateAdditionalTariffsOnHtsChange();
  }, [
    makeHtsValues,
    modifyingAdditional,
    prevPrimaryHtsNumber,
    prevSecondaryHtsNumber,
    prevTertiaryHtsNumber,
    prevQuaternaryHtsNumber,
    primaryHtsNumber,
    secondaryHtsNumber,
    tertiaryHtsNumber,
    quaternaryHtsNumber,
    removeAdditionalTariff,
    accountType,
    readOnly,
    addAdditionalApplicableTariffs,
    prevHtsValues,
  ]);

  // When COO is changed, remove any added tariffs and recalculate which apply and auto add.
  useEffect(() => {
    const handleCountryChange = async () => {
      if (lineCountryOfOrigin === prevLineCountryOfOrigin) {
        return;
      }
      if (accountType !== AccountType.OPERATOR || readOnly) {
        return;
      }
      if (!modifyingAdditional) {
        setModifyingAdditional(true);
        let htsValues = makeHtsValues();
        for (const index of [1, 2, 3]) {
          if (!htsValues.htsObjects[index]) {
            continue;
          }
          const htsObj = htsValues.htsObjects?.[index];
          if (!htsObj) {
            continue;
          }
          const additional: AdditionalHts[] =
            htsObj.additional as AdditionalHts[];
          if (additional) {
            const additionalHtsNumbers = additional.map(
              (additionalHts: AdditionalHts) => {
                return formatHTS(additionalHts.number);
              },
            );
            if (
              additionalHtsNumbers.includes(
                formatHTS(htsValues.htsNumbers[index - 1]),
              )
            ) {
              await removeAdditionalTariff(index - 1);
            }
          }
        }
        htsValues = makeHtsValues();
        await addAdditionalApplicableTariffs(htsValues);
        setModifyingAdditional(false);
      }
    };
    void handleCountryChange();
  }, [
    lineCountryOfOrigin,
    addAdditionalTariff,
    prevLineCountryOfOrigin,
    makeHtsValues,
    removeAdditionalTariff,
    accountType,
    readOnly,
    addAdditionalApplicableTariffs,
    modifyingAdditional,
  ]);

  useEffect(() => {
    const htsValues = makeHtsValues();
    void addAdditionalApplicableTariffs(htsValues);
  }, [addAdditionalApplicableTariffs, getEffectiveUsHtssData, makeHtsValues]);

  const htsInput = (i: number, htsNumber: string, htsObj: any) => {
    const htsValues = makeHtsValues();

    const additional232 = (get(htsObj, 'additional', []) ?? []).filter(
      (a: AdditionalHts) => isSection232(a.type),
    );
    const fullAdditional232Type = get(additional232, [0, 'type'], '');
    const additional232Type = fullAdditional232Type.startsWith('section232')
      ? fullAdditional232Type.substr(10)
      : fullAdditional232Type.substr(12);
    const additional232ExclusionFieldName = `${additional232Type.toLowerCase()}ExclusionNumber`;
    const fullAdditional232ExclusionField = [
      ...fullNamePath,
      additional232ExclusionFieldName,
    ];
    const localAdditional232ExclusionField = [
      ...localNamePath,
      additional232ExclusionFieldName,
    ];
    const additional232ExclusionNumber = getFieldValue(
      fullAdditional232ExclusionField,
    );
    const showExclusionField = i === 0 && additional232.length;

    const onHtsChange = async () => {
      // for tariff tabs, only clear the i-th tariff tab
      clearFields({
        form,
        fullNamePath: [...fullNamePath, 'tariffs', i],
        exclude: ['htsNumber'],
      })();

      // clear all the other tabs, e.g. licenseCode, adCaseNumber, cvCaseNumber,fee, tax
      clearFields({
        form,
        fullNamePath: [...fullNamePath],
        exclude: [
          'tariffs',
          'manufacturer',
          'origin',
          'description',
          'poNumber',
          'externalReference',
          'censusWarnings',
        ],
      })();
      const htsNumber = getFieldValue(htsKey(i));
      const response = await queryEffectiveUsHtss([htsNumber], arrivalDate);
      const htsObj = response[formatHTS(htsNumber)];
      const additionalHts = get(htsObj, 'additionalHTS');

      // clear the old primary HtsNumber if no additionalHts
      if (getFieldValue(htsKey(i + 1)) && !additionalHts) {
        const tariffs = getFieldValue([...fullNamePath, 'tariffs']) || [];
        const setPayload = [
          {
            name: [...fullNamePath, 'tariffs'],
            value: tariffs.slice(0, i + 1),
          },
        ];
        setFields(setPayload);
      }
    };

    return (
      (i === 0 || get(htsValues.htsObjects, [i - 1, 'additionalHTS'])) && (
        <StyledFlexDiv key={`htsInput${i}`}>
          <HTSNumber
            inBondDate={form.getFieldValue([
              ...inBondNamePath,
              'initiationDate',
            ])}
            validate={true}
            required={required && i === 0} // only first tariff is required.
            field={[...localNamePath, 'tariffs', i, 'htsNumber']}
            fullNamePath={[...fullNamePath, 'tariffs', i, 'htsNumber']}
            onChange={onHtsChange} // TODO consider changing this to work via `getValueFromEvent`. The clearing of fields inside of onHtsChange has, in the past, lead to some hard to crack bugs which might be avoided if we cleared _before_ setting the value in the form i.e. having a pre-change cleanup phase, setting the value, letting the form declaritvely react to the change as usual.
            label={
              i > 0 || get(htsObj, 'additionalHTS')
                ? `HTS Number #${i + 1}`
                : 'HTS Number'
            }
            disabled={disabled}
            readOnly={readOnly}
          />

          <LineItemHTSSummary
            accountType={accountType}
            additional232ExclusionNumber={additional232ExclusionNumber}
            fullNamePath={fullNamePath}
            makeHtsValues={makeHtsValues}
            htsNumber={htsNumber}
            htsObj={htsObj}
            htsValues={htsValues}
            index={i}
            loading={htsDataLoading}
            isSection232={isSection232}
            addAdditionalTariff={addAdditionalTariff}
            removeAdditionalTariff={removeAdditionalTariff}
            readOnly={readOnly}
            disabled={disabled}
          />

          {showExclusionField ? (
            <ANNumber
              form={form}
              $itemSize={CssSize.TINY}
              localNamePath={localAdditional232ExclusionField}
              fullNamePath={fullAdditional232ExclusionField}
              label="Exclusion Number"
              debounce={false}
              mask={getExclusionNumber(additional232Type)}
              max={9}
              disabled={disabled}
              readOnly={readOnly}
              required={false}
            />
          ) : null}
        </StyledFlexDiv>
      )
    );
  };

  const htsTariff = (i: number, htsNumber: string, htsObj?: HtsObject) =>
    (i === 0 || get(htsValues.htsObjects, [i - 1, 'additionalHTS'])) &&
    htsObj && (
      <StyledTariffInfoTabPane
        key={form.getFieldValue(htsKey(i))}
        tab={form.getFieldValue(htsKey(i))}
        forceRender={true}
      >
        <Tariff
          form={form}
          htsObj={htsObj}
          tab={form.getFieldValue(htsKey(i))}
          required={required && realHTS(htsNumber)}
          key={`hts${i}`}
          originCountryCode={htsValues.originCountryCode}
          fullNamePath={[...fullNamePath, 'tariffs', i]}
          localNamePath={[...localNamePath, 'tariffs', i]}
          readOnly={readOnly}
          formDisabled={disabled}
          consumptionEntryType={isWorkOrderType}
          isPsc={isPsc}
          setAdTabManuallyAdded={setAdTabManuallyAdded}
          setCvTabManuallyAdded={setCvTabManuallyAdded}
          adTabVisible={adTabVisible}
          cvTabVisible={cvTabVisible}
        />
      </StyledTariffInfoTabPane>
    );

  const htsPGA = (i: number, htsNumber: string, htsObj: any) => {
    const normalPGAFlags = get(htsObj, 'pga', []).filter((flag: string) => {
      return flag in pgaFlags;
    });

    if (htsNumber && htsObj) {
      const extraPGAFlags =
        form.getFieldValue([...fullNamePath, 'tariffs', i, 'extraPgaFlags']) ||
        [];

      const allPGAFlags = [...normalPGAFlags, ...extraPGAFlags];
      clearFields({
        form,
        fullNamePath: [...fullNamePath, 'tariffs', i, 'pga'],
        exclude: allPGAFlags,
      })();

      return allPGAFlags.map((pga) => (
        <StyledPgaFormTab
          key={pga}
          forceRender={true}
          tab={
            <Tooltip
              title={get(agencyCodes, [
                get(pgaFlags, [pga, 'agencyCode']),
                'name',
              ])}
            >
              {get(pgaFlags, [pga, 'agencyCode'])}
            </Tooltip>
          }
        >
          <PgaForm
            key={`hts${i}.${pga}`}
            pgaFlag={pga}
            form={form}
            formDisabled={disabled}
            readOnly={readOnly}
            required={required}
            fullNamePath={[...fullNamePath, 'tariffs', i, 'pga', pga]}
            localNamePath={[...localNamePath, 'tariffs', i, 'pga', pga]}
          />
        </StyledPgaFormTab>
      ));
    }
    return;
  };

  type EmbeddedField = {
    fullNamePath?: (string | number)[];
    validator?: (form: FormInstance<any>) => string[];
  };
  const getRequiredEmbeddedFields = () => {
    const requiredEmbeddedFields: EmbeddedField[] = [
      getNameRequiredField(manufacturerBaseFullNamePath),
      ...getAddressRequiredFields(manufacturerBaseFullNamePath),
      getMidRequiredField(manufacturerBaseFullNamePath),
      getPostalCodeRequiredField(manufacturerBaseFullNamePath),
    ];
    return requiredEmbeddedFields;
  };

  let extraManufacturerSelectProps = {};
  if (isWorkOrderType) {
    extraManufacturerSelectProps = {
      versioned: true,
      embeddedFields: isPsc
        ? usPostSummaryCorrectionSupplierFields
        : usConsumptionEntrySupplierFields,
      requiredEmbeddedFields: getRequiredEmbeddedFields(),
      mapEmbeddedFields: mapEmbeddedSupplierFields,
    };
  }
  const hasCaseIdsStartingWith = (startingChar: string) =>
    get(htsValues.lineHTSObj, 'caseIds', []).some((c: string) =>
      c.startsWith(startingChar),
    );
  const fees = get(htsValues.lineHTSObj, 'fees', []) ?? [];

  const shouldShowAluminumInfoTab = () =>
    isSection232AluminumSmeltAndCastApplicableForAnyTariff(
      htsValues.htsObjects,
      estimatedImportDate ? new Date(estimatedImportDate) : undefined,
    );

  const shouldShowSteelInfoTab = () =>
    isSection232SteelMeltAndPourApplicableForAnyTariff(
      htsValues.htsObjects,
      estimatedImportDate ? new Date(estimatedImportDate) : undefined,
    );

  useEffect(() => {
    if (
      htsValues.htsObjects.some((item) => item) &&
      !shouldShowAluminumInfoTab()
    ) {
      form.setFieldValue([...fullNamePath, 'aluminumRequirements'], undefined);
    }

    if (
      htsValues.htsObjects.some((item) => item) &&
      !shouldShowSteelInfoTab()
    ) {
      form.setFieldValue([...fullNamePath, 'steelRequirements'], undefined);
    }
  }, [estimatedImportDate, form, fullNamePath, htsValues]);

  // The TabBar rendered by Tabs shouldn't be focusable. Will be resolved when this is merged. https://github.com/react-component/tabs/issues/185
  return (
    <StyledTabs
      type="card"
      data-cy={DataCyTabPrefix.LINE_ITEM_TABS}
      tabBarExtraContent={lineActions}
      $noMarginBottom={$noMarginBottom}
    >
      <TabPane forceRender={true} tab="Line Info" key="line-info">
        {HTS_INDICES.map((i) =>
          htsInput(i, htsValues.htsNumbers[i], htsValues.htsObjects[i]),
        )}

        {/* TODO: For HiddenField, we will need to uncomment these when WOA schema updates*/}
        {/* <HiddenField field={[field.name, 'value']} />

        <HiddenField field={[field.name, 'duty']} />

        <HiddenField field={[field.name, 'tax']} />

        <HiddenField field={[field.name, 'fee']} /> */}

        <StyledFlexDiv>
          <PendingUpdatesHighlight
            form={form}
            fullNamePath={[...fullNamePath, 'description']}
          >
            <FormItem
              name={[...localNamePath, 'description']}
              rules={[{ required: required, message: ' ' }]}
              label="CBP Description"
              $itemSize={CssSize.MEDIUM}
              $readOnly={
                readOnly ||
                !!readWriteControlsConfig['invoices.products.lines.description']
                  ?.readOnly
              }
              required={
                required ||
                !!readWriteControlsConfig['invoices.products.lines.description']
                  ?.required
              }
              debounce
            >
              <Input.TextArea autoSize disabled={disabled} maxLength={20000} />
            </FormItem>
          </PendingUpdatesHighlight>
          {!readOnly && (
            <InlineTooltip title={tooltips.description} spaceTop spaceRight />
          )}
          {isWorkOrderType &&
            show({
              readOnly,
              form,
              field: poNumberKey,
            }) && (
              <FormItem
                $itemSize={CssSize.TINY_SHORT}
                name={poNumberKey}
                // rules={[{ required: importer.linePORequired, message: ' ' }]}
                label="PO Number"
                $readOnly={
                  readOnly ||
                  !!readWriteControlsConfig['invoices.lines.poNumber']?.readOnly
                }
                required={
                  required ||
                  !!readWriteControlsConfig['invoices.lines.poNumber']?.required
                }
                debounce
                getValueFromEvent={(e: any) => {
                  const val = e.target.value;
                  return val && val.toUpperCase();
                }}
              >
                <Input disabled={disabled} />
              </FormItem>
            )}
        </StyledFlexDiv>
        <StyledInlineDiv>
          {/* had to explicitly list the field itself in shouldUpdate so that it re-renders when being set by handleSetSeller in CommercialInvoiceTab*/}
          <Form.Item
            noStyle
            shouldUpdate={shouldUpdate([[...manufacturerKey, 'id']])}
          >
            {() => (
              <RecordSelect
                recordType={RecordType.SUPPLIER}
                $itemSize={CssSize.SHORT}
                form={form}
                localNamePath={manufacturerRecordSelectField}
                fullNamePath={manufacturerKey}
                // Disable the manf selection on Entry line if existing product is selected/used.
                disabled={disabled || entryLineProductId}
                forceAlowRefresh
                // create={true} TODO add back soon
                label="Manufacturer"
                readOnly={
                  readOnly ||
                  !!readWriteControlsConfig[
                    'invoices.products.lines.manufacturer'
                  ]?.readOnly
                }
                required={
                  required ||
                  !!readWriteControlsConfig[
                    'invoices.products.lines.manufacturer'
                  ]?.required
                }
                create={true}
                {...extraManufacturerSelectProps}
              />
            )}
          </Form.Item>
          <CountrySelect
            // Disable the COO selection on Entry line if existing product is selected/used.
            disabled={disabled || entryLineProductId}
            readOnly={
              readOnly ||
              entryLineProductId ||
              !!readWriteControlsConfig['invoices.products.lines.origin']
                ?.readOnly
            }
            required={
              required ||
              !!readWriteControlsConfig['invoices.products.lines.origin']
                ?.required
            }
            localNamePath={[...localNamePath, 'origin', 'countryCode']}
            label="Origin Country"
            onChange={(value) => {
              // stateCode is only for Canada thus we should clear it onChange
              setFields([
                {
                  name: [...fullNamePath, 'origin', 'stateCode'],
                  value: undefined,
                },
              ]);
              setLineCountryOfOrigin(value);
            }}
            notAllowedCountryCodes={['BY']}
          />
          <Form.Item
            noStyle
            shouldUpdate={shouldUpdate([originCountryCodeKey])}
          >
            {() => {
              const originCountryCode =
                form.getFieldValue(originCountryCodeKey);
              return (
                originCountryCode === 'CA' && (
                  <CanadianProvinceSelect
                    // If the select is shown (i.e. `CA` `countryCode` is
                    // selected), it is required
                    required
                    disabled={disabled}
                    readOnly={readOnly}
                    localNamePath={[...localNamePath, 'origin', 'stateCode']}
                  />
                )
              );
            }}
          </Form.Item>
        </StyledInlineDiv>
        <StyledInlineDiv>
          <INLTSwitch
            onWord="Yes"
            offWord="No"
            label="Is the manufacturer related to the importer?"
            field={relatedManufacturer}
            readOnly={readOnly}
            disabled={disabled}
          />
        </StyledInlineDiv>
        <StyledHelpLinkDiv>
          <HelpLink
            link="https://help.inlt.com/customs-brokerage/determining-country-of-origin"
            text="Determining country of origin"
          />
        </StyledHelpLinkDiv>
        <div />
        <StyledFormItem
          shouldUpdate={shouldUpdate([
            [...fullNamePath, 'origin', 'countryCode'],
          ])}
        >
          {() => {
            const htsValues = makeHtsValues();
            return (
              <>
                {htsValues.originCountryCode && htsValues.lineHtsNumber && (
                  <SPISelect
                    form={form}
                    disabled={disabled}
                    readOnly={readOnly}
                    fullNamePath={[...fullNamePath, 'spi']}
                    localNamePath={[...localNamePath, 'spi']}
                    originCountryCode={htsValues.originCountryCode}
                    htsNumber={htsValues.lineHtsNumber}
                    htsObj={htsValues.lineHTSObj}
                    entryDate={
                      // form.getFieldValue('estimatedEntryDate') ||
                      // apparently the import date is all that matters here
                      form.getFieldValue(['arrival', 'importDate'])
                    }
                  />
                )}
              </>
            );
          }}
        </StyledFormItem>
        {isPsc && (
          <Select
            form={form}
            disabled={disabled}
            readOnly={readOnly}
            localNamePath={[...localNamePath, 'lineReasons']}
            fullNamePath={[...fullNamePath, 'lineReasons']}
            multiple
            map={pscLineReasonCodes}
            label="PSC Reasons"
            max={5}
            required={false}
            $itemSize={CssSize.MEDIUM_LARGE}
          />
        )}
      </TabPane>
      {htsValues.licenseCode && (
        <TabPane tab="License Info" key="license-info" forceRender={true}>
          <HiddenField
            localNamePath={[...localNamePath, 'licenseCode']}
            value={htsValues.licenseCode}
            fullNamePath={[...fullNamePath, 'licenseCode']}
            form={form}
          />
          <StyledInlineDiv>
            <FormItem
              $itemSize={CssSize.MEDIUM}
              help={null}
              label={`${licenseCodes[htsValues.licenseCode]} Number`}
              name={[...localNamePath, 'licenseNumber']}
              rules={[
                {
                  // if this changes, please also update the validation for licenseTypeCode
                  // in constructCbpSharedEntryData
                  required:
                    required && ['01', '28'].includes(htsValues.licenseCode),
                  message: ' ',
                },
              ]}
              $readOnly={readOnly}
              debounce
            >
              <Input disabled={disabled} />
            </FormItem>
            {/* TODO this is broken. accountType === AccountType.OPERATOR &&
            isWorkOrderType &&
            htsValues.licenseCode &&
            consumptionEntry ? (
              <StyledSteelLicenseGenerateEntryNumberDiv>
                {entryNumber || (
                  <Button
                    onClick={handleGenerateEntryNumber}
                    disabled={isFetching}
                    type="default"
                  >
                    Generate Entry Number
                  </Button>
                )}
              </StyledSteelLicenseGenerateEntryNumberDiv>
            ) : null */}
          </StyledInlineDiv>
        </TabPane>
      )}
      {fees.map((fee: any, index: number) => {
        return (
          fee.class && (
            <TabPane
              key={fee.class}
              tab={{ ...lineFees, ...taxClass }[fee.class]}
              forceRender={true}
            >
              {lineFees[fee.class] && (
                <>
                  <HiddenField
                    localNamePath={[
                      ...localNamePath,
                      'feeOptions',
                      index,
                      'required',
                    ]}
                    value={true}
                    fullNamePath={[
                      ...fullNamePath,
                      'feeOptions',
                      index,
                      'required',
                    ]}
                    form={form}
                  />
                  <HiddenField
                    localNamePath={[
                      ...localNamePath,
                      'feeOptions',
                      index,
                      'type',
                    ]}
                    value={usCbpEntryFeeCodeToTypeMap.get(fee.class)} // legacy always had this as true?
                    fullNamePath={[
                      ...fullNamePath,
                      'feeOptions',
                      index,
                      'type',
                    ]}
                    form={form}
                  />
                </>
              )}
              {taxClass[fee.class] && (
                <>
                  <HiddenField
                    localNamePath={[
                      ...localNamePath,
                      'taxOptions',
                      index,
                      'required',
                    ]}
                    value={true} // legacy always had this is as true?
                    fullNamePath={[
                      ...fullNamePath,
                      'taxOptions',
                      index,
                      'required',
                    ]}
                    form={form}
                  />
                  <HiddenField
                    localNamePath={[
                      ...localNamePath,
                      'taxOptions',
                      index,
                      'type',
                    ]}
                    value={usCbpEntryTaxCodeToTypeMap.get(fee.class)}
                    fullNamePath={[
                      ...fullNamePath,
                      'taxOptions',
                      index,
                      'type',
                    ]}
                    form={form}
                  />
                </>
              )}
              <StyledLineFeeParagraph>
                {lineFees[fee.class]
                  ? 'Fee'
                  : taxClass[fee.class]
                  ? 'Tax'
                  : 'Fee'}{' '}
                {fee.required ? 'is required' : 'may be required'}:{' '}
                {dutyComputation({
                  // TODO remove first half of the below || expressions after HTS automation UAT
                  htsNumber: htsValues.lineHtsNumber,
                  duty: fee.duty || fee.rateCode,
                  rate: {
                    adValorem: fee.feeAdValorem || fee.rate?.adValorem,
                    specific: fee.feeSpecific || fee.rate?.specific,
                  },
                  uom: htsValues.lineHTSObj?.uom,
                })}
              </StyledLineFeeParagraph>
              {(!fee.required || fee.class === '056') && (
                <Checkbox
                  $inline
                  $removeSpaceBottom
                  form={form}
                  readOnly={readOnly}
                  disabled={disabled}
                  fullNamePath={[
                    ...fullNamePath,
                    lineFees[fee.class] ? 'feeOptions' : 'taxOptions',
                    index,
                    'disclaim',
                  ]}
                  localNamePath={[
                    ...localNamePath,
                    lineFees[fee.class] ? 'feeOptions' : 'taxOptions',
                    index,
                    'disclaim',
                  ]}
                  text="Disclaim?"
                />
              )}
              <StyledFormItem
                noStyle
                shouldUpdate={shouldUpdate([
                  [...fullNamePath, 'taxOptions', index, 'disclaim'],
                ])}
              >
                {() => {
                  const disclaim = getFieldValue([
                    ...fullNamePath,
                    'taxOptions',
                    index,
                    'disclaim',
                  ]);
                  return (
                    <>
                      {!disclaim && taxClass[fee.class] && (
                        <Checkbox
                          $removeSpaceBottom
                          form={form}
                          readOnly={readOnly}
                          disabled={disabled}
                          fullNamePath={[
                            ...fullNamePath,
                            'taxOptions',
                            index,
                            'defer',
                          ]}
                          localNamePath={[
                            ...localNamePath,
                            'taxOptions',
                            index,
                            'defer',
                          ]}
                          text="Defer?"
                        />
                      )}
                      {!disclaim && ['016', '017'].includes(fee.class) && (
                        <Checkbox
                          $removeSpaceBottom
                          form={form}
                          readOnly={readOnly}
                          disabled={disabled}
                          fullNamePath={[...fullNamePath, 'productClaimCode']}
                          localNamePath={[...localNamePath, 'productClaimCode']}
                          text="Craft Beverage Modernization Act Refund"
                        />
                      )}
                      {!disclaim && taxClass[fee.class] && (
                        <MoneyInput
                          fullNamePath={[
                            ...fullNamePath,
                            'taxOptions',
                            index,
                            'rate',
                          ]}
                          localNamePath={[
                            ...localNamePath,
                            'taxOptions',
                            index,
                            'rate',
                          ]}
                          label={`Tax Rate / ${get(htsValues.lineHTSObj, [
                            'uom',
                            0,
                          ])}`}
                          $itemSize={CssSize.TINY}
                          max={100}
                          precision={6}
                          disabled={disabled}
                          readOnly={readOnly}
                          required={required}
                        />
                      )}
                    </>
                  );
                }}
              </StyledFormItem>
            </TabPane>
          )
        );
      })}
      {/* For some HTS (like 6907.30.9051) ad/cvd property is not set, but
         special ad/cvd case ids might be applicable.
         TODO: clean up these checks and remove htsValues.lineHTSObj.ad
         when Hts.adCvdCases are fully populated.
        */}
      {adTabVisible && (
        <TabPane tab="Anti-Dumping Duty" key="ad" forceRender={true}>
          <LineItemCase
            key={keyRef?.current}
            type="ad"
            recordType={recordType}
            principalAdCvdCases={adCases}
            htsNumber={htsValues.lineHtsNumber}
            readOnly={
              readOnly ||
              !!readWriteControlsConfig['invoices.products.lines.adCase']
                ?.readOnly
            }
            required={
              required ||
              !!readWriteControlsConfig['invoices.products.lines.adCase']
                ?.required
            }
            disabled={disabled}
            fullNamePath={[...fullNamePath, 'adCase']}
            localNamePath={[...localNamePath, 'adCase']}
          />
        </TabPane>
      )}
      {cvTabVisible && (
        <TabPane tab="Countervailing Duty" key="cv" forceRender={true}>
          <LineItemCase
            type="cv"
            recordType={recordType}
            principalAdCvdCases={cvCases}
            key={keyRef?.current}
            htsNumber={htsValues.lineHtsNumber}
            readOnly={
              readOnly ||
              !!readWriteControlsConfig['invoices.products.lines.cvCase']
                ?.readOnly
            }
            required={
              required ||
              !!readWriteControlsConfig['invoices.products.lines.cvCase']
                ?.required
            }
            disabled={disabled}
            fullNamePath={[...fullNamePath, 'cvCase']}
            localNamePath={[...localNamePath, 'cvCase']}
          />
        </TabPane>
      )}
      {[0, 1, 2, 3].map((i) =>
        htsTariff(i, htsValues.htsNumbers[i], htsValues?.htsObjects[i]),
      )}
      {!isPsc &&
        [0, 1, 2, 3].map((i) =>
          htsPGA(i, htsValues.htsNumbers[i], htsValues?.htsObjects[i]),
        )}
      {hasDisplayableCensusWarnings ? (
        <TabPane tab="Census Warnings" key="census" forceRender>
          <CensusWarningsTab
            localNamePath={localNamePath}
            warnings={censusWarnings}
          />
        </TabPane>
      ) : null}

      {shouldShowAluminumInfoTab() ? (
        <TabPane tab={'Aluminum Info'} key={'aluminumInfo'} forceRender={true}>
          <AluminumSmeltAndCastCountryDetail
            fullNamePath={fullNamePath}
            localNamePath={localNamePath}
          />
        </TabPane>
      ) : null}

      {shouldShowSteelInfoTab() ? (
        <TabPane tab={'Steel Info'} key={'steelInfo'} forceRender={true}>
          <SteelMeltAndPourCountryDetail
            fullNamePath={fullNamePath}
            localNamePath={localNamePath}
          />
        </TabPane>
      ) : null}
    </StyledTabs>
  );
};
export default LineItem;
